<div class="modal-header">
  <div style="display: flex; flex-direction: column"><h5 class="modal-title">Request a Story</h5>
    Create a link to share. Include a popup personal note. </div>
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">
          <svg role="img">
              <use xlink:href="#icon-close"></use>
          </svg></span></button>
</div>
<div class="modal-body request-story" [formGroup]="requestForm"> 
  <div class="headings_of_invite_content">
      <div class="row" *ngIf="!initialState.hidetopbar">
        <div class="col-4" style="white-space: nowrap;">
        <h6>Story From</h6>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" (change)="Chnageuserstatus($event)"  formControlName="userStatus" value="user"
                    class="custom-control-input">
                <span class="custom-control-label">User</span>
            </label>
        </div>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" (change)="Chnageuserstatus($event)" formControlName="userStatus" value="Guestuser"
                    class="custom-control-input">
                <span class="custom-control-label">Guest</span>
            </label>
        </div>
        </div>
       </div>
    </div>

    <ng-container *ngIf="requestForm.value.userStatus == 'user'">
      <!--<h6 class="font-weight-bold mt-3 mr-3">Invited author</h6>-->
      <div (keyup.enter)="handleComKeyEnter()" class="story-flow-view__box view-box-people outline-none"
          [ngClass]="{'current': !viewmodel.isStoryCreating}">
  
          <form [hidden]="isCopyCode"
              [formGroup]="form" class="view-box-people__search setting_form" (keyup.enter)="exceptHandleComKeyEnter($event)">
              <div class="view-box-media__tab-item ml-1">
                  <div class="search-input">
                      <input id="searchUnsplash"formControlName="keyword" type="text" placeholder="Search"
                          class="form-control" autofocus>
                  </div>
                  <div class="view-box-people__search-button button-label" [ngClass]="{'searching': viewmodel.searching}">
                      <div [hidden]="!viewmodel.searching" class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                      <button [hidden]="viewmodel.searching" type="submit"><i class=""></i></button>
                  </div>
              </div>
  
          </form>
          <div class="view-box-people__result spacer">
              <div class="no___members" *ngIf="members && members?.length == 0">
                NO USER FOUND.Invite them to submit as a guest.
                </div>
              <scrollbar #scrollbar *ngIf="members && members?.length > 0" (onYReachEnd)="onYReachEnd()"
                  (onPositionChanged)="onPositionChanged()">
                  <div class="l-people-result">
  
                      <label class="l-people-result__item"
                          *ngFor="let player of members; index as i; trackBy: memberTrackByFnc"
                          title="{{player.title ? player.title : ''}}">
  
                          <span class="l_people_request">
                              <gs-avatar [src]="player.avatar" isBackground="true" classCss="avatar"></gs-avatar>
                          </span>
  
                          <ng-container *ngIf="player.storyInviteEnabled !== false">
                              <span class="l-people-result__content">
                                  <span class="l-people-result__name">{{player.displayName}}&nbsp; -</span>
                                  <span class="l-people-result__mail">&nbsp;{{player.email}}</span>
                              </span>
                              <span class="l-people-result__checkbox" *ngIf="!isCopyCode">
                                  <input type="checkbox" [(ngModel)]="player.checked" [ngModelOptions]="{standalone: true}" (change)="selectItem(player)">
                                  <span>
                                  </span>
                              </span>
                              <span class="l-people-result__checkbox" *ngIf="isCopyCode">
                                  <span data-toggle="tooltip" #copyToolTip class="copy-text-{{i}}" data-placement="right"
                                      title="Click to copy"><span></span>
                                      <span (click)="copyMessage(player,copyToolTip)"><i
                                              class="fas fa-link"></i></span>
                                      <span>
                                      </span>
                                  </span>
                              </span>
                          </ng-container>
  
                          <ng-container *ngIf="player.storyInviteEnabled === false && !player.checked">
                              <span class="l-people-result__content">
                                  <span class="l-people-result__name text-dark">
                                      <span data-toggle="tooltip" data-placement="right" title="Unavailable to add"
                                          class="pr-2"><span>{{player.displayName}}</span>
                                          <svg role="img" class="icon16">
                                              <use xlink:href="#icon-info"></use>
                                          </svg>
                                      </span>
                                  </span>
                                  <span class="l-people-result__mail">-&nbsp; {{player.email}}&nbsp;</span>
                              </span>
                          </ng-container>
                          <span class="copied-success" *ngIf="player?.linkCopied">Link copied!</span>
                      </label>
                      <div class="l-story-box-media__loading" [hidden]="(!loadMore || viewmodel.searching)" *ngIf="!isCopyCode"
                      >
                          Loading more ....
                       
                      </div>
                  </div>
              </scrollbar>
              <div class="container mt-5 text-center"
                  *ngIf="(viewmodel.isPlayerCreating || (viewmodel.isPlayerLoading && !(members && members?.length > 0)))">
                  <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                  </div>
              </div>
          </div>
      </div>
      <ng-container *ngIf="showRequestedUser && requestedMembers.length > 1 && !isCopyCode">
        <h6>Add popup message from:</h6>
        <div class="job__select mb-2" >
          <ng-select
          class="job-select"
          [placeholder]="''"
          [items]="requestedMembers"
          [multiple]="false"
          [closeOnSelect]="true"
          [selectableGroup]="true"
          maxSelectedItems ="1"
          bindValue="id"
          bindLabel="title"
          formControlName="userselcted"
          >
          <ng-template ng-option-tmp let-item="item" let-item$="item$"  let-index="index">
            <div class="mobile-check custom-control custom-checkbox">
              <input id="item-{{index}}" class="custom-control-input"  type="checkbox"  [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected"/> 
              <label class="custom-control-label">{{item.title}}</label>
            </div>
          </ng-template>
        </ng-select>
        </div>
      </ng-container>
      <div class="row" *ngIf="!isCopyCode">
        <div class="col-12">
          <h6>Personal Message</h6>
          <gs-area-material
          label=""
          name="Message" placeholder="What happened? Why does it matter?" rows="2"
          cssClass="spacing-lg" formControlName="internalmessage" [formControl]="requestForm.controls.internalmessage">
      </gs-area-material>
        </div>

      </div>


     


  <div class="generate__box">

      <ng-container *ngIf="!hideSendanCopylinkButton">
        <div>
          <button [style.background]="primary_color ? primary_color : ''"  type="button" [disabled]="!selectedData.length || isLoading || requestForm.invalid ||  diableonsearching" (click)="GenearateInternalMainlLink()" class="btn btn-pink min-width">
        Generate Link
        </button>
        </div>
      </ng-container>
      <ng-container>
        <div *ngIf="hideSendanCopylinkButton">
          <button [style.background]="primary_color ? primary_color : ''" type="button" (click)="sendIntenalMainEmail()" class="btn btn-pink min-width">
            Send to Selected
        </button>
        </div>
    
      </ng-container>
    
    
    </div>

    </ng-container>

    <ng-container *ngIf="requestForm.value.userStatus == 'Guestuser'">
      <!--<h6 class="">Author Details</h6>-->
      <div class="pt-3">
        <div class="row">
          <div class="col-6">
            <!-- <h6>Firstname</h6> -->
            <gs-input-material label="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" name="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" placeholder="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" formControlName="firstname" [formControl]="requestForm.controls.firstname" type="text"></gs-input-material>
          </div>
          <div class="col-6">
            <!-- <h6>Lastname</h6> -->
            <gs-input-material label="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" name="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" placeholder="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" [formControl]="requestForm.controls.lastname" type="text"></gs-input-material>
          </div>
        </div>
  
        <div class="row">
            <div class="col-12">
              <!-- <h6>Email (Optional)</h6> -->
              <gs-input-material  label="{{!requestForm.controls.email.disabled ? 'Email (Optional)' : ''}}" name="{{!requestForm.controls.email.disabled ? 'Email (Optional)' : ''}}" placeholder="!requestForm.controls.email.disabled ? 'Email (Optional)' : ''" [formControl]="requestForm.controls.email" type="text"></gs-input-material>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <gs-input-material label="{{!requestForm.controls.note_email.disabled ? 'Notes (Optional)' : ''}}" name="{{!requestForm.controls.email.disabled ? 'Notes (Optional)' : ''}}" placeholder="!requestForm.controls.note_email.disabled ? 'Notes (Optional)' : ''" formControlName="note_email" [formControl]="requestForm.controls.note_email" type="text"></gs-input-material>
        </div>
      </div>
      </div>
      <ng-container *ngIf="showRequestedUser && requestedMembers.length > 1">
        <h6>Add popup message from:</h6>
        <div class="job__select mb-2" >
          <ng-select
          class="job-select"
          [placeholder]="''"
          [items]="requestedMembers"
          [multiple]="false"
          [closeOnSelect]="true"
          [selectableGroup]="true"
          maxSelectedItems ="1"
          bindValue="id"
          bindLabel="title"
          formControlName="externalUserSelcted"
          >
          <ng-template ng-option-tmp let-item="item" let-item$="item$"  let-index="index">
            <div class="mobile-check custom-control custom-checkbox">
              <input id="item-{{index}}" class="custom-control-input"  type="checkbox"  [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected"/> 
              <label class="custom-control-label">{{item.title}}</label>
            </div>
          </ng-template>
        </ng-select>
        </div>
      </ng-container>

  
  
     <div class="row" [formGroup]="requestForm">
    
          <div class="col-12">
            <h6>Personal Message</h6>
            <gs-area-material
            label=""
            name="about" placeholder="What happened? Why does it matter?" rows="2"
            cssClass="spacing-lg" formControlName="message"    [formControl]="requestForm.controls.message">
        </gs-area-material>
           </div>
      </div>
  
      <div class="generate__box">
  
        <ng-container *ngIf="!hideSendanCopylinkButton">
          <div>
            <button [style.background]="primary_color ? primary_color : ''" type="button" [disabled]="!requestForm.valid" (click)="GenearateExternalMainlLink()" class="btn btn-pink min-width">
          Generate Link
          </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isSingleInternalRequested">
          <div>
            <button type="button" (click)="copyExternalUrl()"  class="invite_guest btn  min-width">

              {{buttonText}} &nbsp; <span><i class="fas fa-link" style="color: #1579f6"></i></span>
          </button>
          </div>
        </ng-container>
        <ng-container *ngIf="isSingleInternalRequested">
          <div class="ml-3">
            <button type="button" (click)="shareadthis()"  class="invite_guest btn  min-width">
              Share &nbsp; <span><i class="fa-regular fa-share" style="color: #1579f6"></i></span>
            </button>
          </div>
        </ng-container>
        <div class="remark-box__adthis" [@showhideadthis]="isDispalyadthis ? 'show' : 'hide'">
          <div class="close__popup_Design">
            <button class="btn btn-trans ml-3" (click)="closeshare()">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-collapse"></use>
              </svg>
            </button>
          </div>
          <div #shareholic class="shareaholic-canvas" data-app="share_buttons" data-app-id="33117316"></div>
        </div>
      
      </div>
    </ng-container>

</div>
