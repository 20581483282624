
<ng-container *ngIf="isBrowser">
  <ng-container *ngIf="!isDeleted">
    <div class="site-container detail_page_public" [ngClass]="{'no-padding': isEmbedMode}">
        <!-- START HEADER-->
        <app-external-header *ngIf="!isEmbedMode"></app-external-header>
    
        <!-- CLOSE HEADER-->
        <ng-container *ngIf="!storyDetail?.isNoPermission">
            <!-- START CONTENT-->
            <div class="b-story spacer">
                <div class="b-story-head">
                    <app-pb-author-informations class="b-story-head__author mr-auto"></app-pb-author-informations>
                    <ng-container>
                      <div class="b-story-head-actions" *ngIf="!isEmbed">
                        <div class="share-icons-next">

                          <button  [ngClass]="{'disableClick': !storyDetail?.prev_story_slug }"
                          [routerLink]="['/c/'+ currentCompany?.slug + '/' + storyDetail?.prev_story_slug]" [disabled]="!storyDetail?.prev_story_slug" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParamsDeatil" routerLinkActive="active" class="btns">
                          <span class="previousbutton"><i class="fa fa-chevron-left" style="padding-right: 5px"></i>Back</span></button>

                          <button [ngClass]="{'disableClick': !storyDetail?.next_story_slug }" [disabled]="!storyDetail?.next_story_slug"  [routerLink]="['/c/'+ currentCompany?.slug + '/' + storyDetail?.next_story_slug]" [queryParams]="queryParamsDeatil"
                          [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" class="btns"><span class="nextbutton">Next<i class="fa fa-chevron-right" style="padding-left: 5px"></i></span></button>
                        </div>
                        <div class="previous-next" *ngIf="(storyDetail && storyDetail.public)">  
                          <span class="previousbutton"> &nbsp;</span> 
                          <button   class="btns button_style"(click)="story()"><span class="previousbutton">Copy &nbsp;</span><i class="fa-regular fa-link"></i></button>
                          <!-- <button   class="btns button_style" *ngIf="" (click)="embedLinks()"><span class="nextbutton">Embed &nbsp;</span><i class="fa-thin fa-code"></i></button> -->
                          <button    class="btns button_style" (click)="social()"><span class="previousbutton">Socials &nbsp;</span><i class="fa-regular fa-share"></i></button>
                        </div>
                      </div>
                      </ng-container>

                    <app-pb-story-toolbar *ngIf="!isEmbedMode && !isEmbed"></app-pb-story-toolbar>
                    <skeleton-pb-story-toolbar *ngIf="!isEmbedMode && !isEmbed"></skeleton-pb-story-toolbar>
                </div>
            </div>
            <div class="b-story-body">
                <!-- <app-pb-steps-bar class="m-story-tags d-block"></app-pb-steps-bar> -->
                <div class="contributor_container" *ngIf="!isSmallScreen">
                  <app-pb-contributor-list ></app-pb-contributor-list>
                </div> 
                <div class="m-story-main">
                  <app-pb-steps-bar *ngIf="isSmallScreen" class="m-story-tags d-block"></app-pb-steps-bar>
                    <div class="m-story-content">
                        <div class="m-story-content__inner">
                            <div class="m-story-content__banner" *ngIf="storyDetail?.media  && storyDetail?.media.length">
                              <div class="slideshow-container"  [style.background-image]="backgroundImageUrl">
                                <div class="mySlides" *ngFor="let item of mediaPayload ; let i= index" [ngClass]="{'fade': i == activeIndex,'hidedisplay':i != activeIndex, 'showDisplay': i == activeIndex}">
                                  <ng-container *ngIf="!isVideo(item.type)">
                                    <img [src]="item.imageUrl" />
                                  </ng-container>
                                  <ng-container *ngIf="isVideo(item.type)">
                                    <!-- <video-control [src]="storyDetail.media.fullsize" [type]="storyDetail.media.saveIn"></video-control> -->
                                    <!-- <iframe id="detail-public-board_iframe" [src]="item.url" width="100%" frameBorder="0"></iframe> -->
                                    <mux-player  stream-type="on-demand"
                                    attr.playback-id='{{item.url}}' attr.title="{{item.title}}" attr.poster="{{item.thumbnail_url}}"
                                    attr.primary-color="{{primaryColor}}"
                                    attr.accent-color="{{accentColor}}">
                                  </mux-player>
                                  <div class="group-action">
                                    <div>
                                      <button class="btn-action" (click)="downloadVideo(item)" title="Open Editor">
                                        <i class="fa-solid fa-download"></i>
                                      </button>
                                    </div>
                                  </div>
                      
                                  </ng-container>
                                </div>
                                <!-- <a class="prev" (click)="plusSlides(-1)" *ngIf="mediaPayload.length > 1"> <span class="previous_slide">❮</span></a>
                                <a class="next" (click)="plusSlides(1)" *ngIf="mediaPayload.length > 1" ><span class="next_slide">❯</span></a> -->
                            </div>
                            </div>
                            <div style="text-align:center" *ngIf="storyDetail?.media && storyDetail?.media.length">
                              <span class="dot" (click)="currentSlide(i)"  *ngFor="let item of mediaPayload ; let i = index" [ngClass]="{'showIndiacatr': i == activeIndex}"></span> 
                              <!-- <span class="dot" (click)="currentSlide(i)"></span> 
                              <span class="dot" (click)="currentSlide(item)"></span>  -->
                            </div>
                            <div class="m-story-content__banner" [hidden]="!statusDetail.isLoading">
                                <gs-skeleton-loader height="500"></gs-skeleton-loader>
                            </div>
                            <div class="m-story-content__content">
                                <h1 class="m-story-content__content-title">{{storyDetail?.title}}</h1>
                                <ng-container *ngIf="showQuestion  && storyDetail && !storyDetail?.interview_style && storyDetail?.show_prompt">
                                    <div class ="stroy-prompt" > {{showQuestion}}
                                    </div>
                                  </ng-container>

                                  <ng-container *ngIf="storyDetail  && !storyDetail?.interview_style">
                                    <quill-editor [(ngModel)]="quillData" theme="bubble" [placeholder]="' '"
                                    [modules]="quillModule" [readOnly]="true"></quill-editor>
                                  </ng-container>
                  
                                  <ng-container *ngIf="storyDetail  && storyDetail?.interview_style && storyDetail?.show_prompt && showPromptonInterviewStyle">
                                    <ng-container *ngFor="let item of interviewcontent ; let i = index ">
                                      <div class="interview_contet" *ngIf="item.body">
                                        <ng-container  *ngIf="item.body">
                                          <span *ngIf="storyDetail?.show_prompt && showPromptonInterviewStyle" class="stroy-prompt">
                                            {{item.question}}
                                           </span> <br *ngIf="storyDetail?.show_prompt && showPromptonInterviewStyle"/>
                                           <quill-editor [(ngModel)]="item.body" theme="bubble" [placeholder]="' '"
                                           [modules]="quillModule" [readOnly]="true"></quill-editor>
                                        </ng-container>
                                      </div>
                                    </ng-container>
                            
                                  </ng-container>
                                  <ng-container *ngIf="storyDetail  && storyDetail?.interview_style &&  (!storyDetail?.show_prompt ||  !showPromptonInterviewStyle)">
                                    <quill-editor [(ngModel)]="quillData" theme="bubble" [placeholder]="' '"
                                    [modules]="quillModule" [readOnly]="true"></quill-editor>
                                  </ng-container>
                                <!-- <div class="m-story-content__content-post" [innerHtml]="storyDetail?.content"></div> -->
                                <gs-skeleton-loader class="m-story-content__content-title" width="500" height="44"
                                    [hidden]="!statusDetail.isLoading">
                                </gs-skeleton-loader>
                                <gs-skeleton-loader [hidden]="!statusDetail.isLoading"
                                    class="m-story-content__content-post">
                                </gs-skeleton-loader>
                                <gs-skeleton-loader [hidden]="!statusDetail.isLoading"
                                    class="m-story-content__content-post">
                                </gs-skeleton-loader>
                                <gs-skeleton-loader [hidden]="!statusDetail.isLoading"
                                    class="m-story-content__content-post">
                                </gs-skeleton-loader>
                            </div>
                            <ng-container *ngIf="showFootnote">
                              <app-pb-nudge-preview [data]="measureId" (nudgeEvnt)="showDonation($event)"></app-pb-nudge-preview>
                            </ng-container>
                        </div>
                    </div>
                    <div class="detail_steps_bar" [ngStyle]="{'width': no_Tags ? '17%' : ''}" *ngIf="!isSmallScreen">
                      <app-pb-steps-bar  class="m-story-tags d-block"></app-pb-steps-bar>
                    </div>
                    
                    <div class="m-story-aside">
                        <app-pb-contributor-list *ngIf="isSmallScreen"></app-pb-contributor-list>
                        <app-pb-comment-list [isMasterAdmin]="false"  class="comment_section"></app-pb-comment-list>
                    </div>
                </div>
            </div>
            <!-- CLOSE CONTENT-->
            <app-pb-comment-list [isMasterAdmin]="false" class="hide_comment_section"></app-pb-comment-list>
        </ng-container>
    
        <app-pb-no-permision-content *ngIf="storyDetail?.isNoPermission || storyDetail?.archived">
        </app-pb-no-permision-content>
    </div>
    <!-- <div class="remark-box"  *ngIf="!isSmallScreen">

        <div class="remark-conatiner anim-pulse-cta">
          <div class="close__popup_Design">
            <span >
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
      
          <div class="row">
            <div class="col-2 mt-4 ml-4">
              <gs-avatar  [src]="'assets/front-end-minify/img/avatar/avatar04.jpg'" classCss="avatar"></gs-avatar>
            </div>
          <div class="col-8 mt-4">
            <p class="content-data">Hello This is your reviewHello This is your reviewHello This is your reviewHello This is your reviewHello This is your review</p>
            <p class="content-created">-Demo</p>
          </div>
          </div>
        
        </div>
      
      </div>
      
      <div class="remark-box--mobile" *ngIf="isSmallScreen">
      
        <div class="remark-conatiner anim-pulse-cta">
          <div class="close__popup_Design">
            <span >
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
      
          <div class="row">
            <div class="col-2 mt-4 ml-4">
              <gs-avatar  [src]="'assets/front-end-minify/img/avatar/avatar04.jpg'" classCss="avatar"></gs-avatar>
            </div>
          <div class="col-8 mt-4">
            <p class="content-data">Hello This is your reviewHello This is your reviewHello This is your reviewHello This is your reviewHello This is your review</p>
            <p class="content-created">-Demo</p>
          </div>
          </div>
        
        </div>
      
      </div> -->
    <app-site-footer *ngIf="!isEmbed"></app-site-footer>  
</ng-container>
<div class="" *ngIf="showDonor" (click)="closeDonor()" [ngClass]="{'cross_btn':showDonor}">
  <i class="fa-solid fa-x"></i>
</div>
<div class="" (click)="closeDonor()" [ngClass]="{'background':showDonor}">
</div>
<div id="donation_form" (click)="closeDonor()" [ngClass]="{'donor__iframe_container':showDonor && !isSmallScreen , 'donor__iframe_container__mobile': isSmallScreen && showDonor}">
</div>
</ng-container>

<div id="downloadfile"></div>



