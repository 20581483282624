import { Component, SimpleChanges, Input, forwardRef, ElementRef, Renderer2, Output } from '@angular/core';

import { ComponentBase } from '@framework/base/component/component.base';
import { Constants } from '../../../constants';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-grid-view-dynamic-field',
  templateUrl: './grid-view-dynamic-field.component.html',
  styleUrls: ['./grid-view-dynamic-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridViewDynamicFieldComponent),
      multi: true
    }
  ]
})
export class GridViewDynamicFieldComponent extends ComponentBase implements ControlValueAccessor {
  @Input() type: string;
  @Input() disabled: boolean = false;
  @Input() item:any;
  @Output() 

  // Private variables
  private inputValue: string;
  public toggleColor:any;
  // Public variables
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  constructor(private el: ElementRef, private renderer: Renderer2) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {
    this.toggleColor = localStorage.getItem('toggleColor')
     this.toggleColor = this.toggleColor ? this.toggleColor : ''
  }

  private unsubscribe() {

  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }
  // Public functions
  public get isText(): boolean {
    return this.type === Constants.columnType.text;
  }

  public get isCheckBox(): boolean {
    return this.type === Constants.columnType.checkbox;
  }

  public get isNullableCheckBox(): boolean {
    return this.type === Constants.columnType.nullablecheckbox;
  }
  public get isDate(): boolean {
    return this.type === Constants.columnType.date;
  }

  public get isStatus(): boolean {
    return this.type === Constants.columnType.status;
  }

  public get isIcon(): boolean {
    return this.type === Constants.columnType.icon;
  }

  public get isdIcon(): boolean {
    return this.type === Constants.columnType.dicon;
  }


  public get isColor(): boolean {
    return this.type === Constants.columnType.color;
  }

  public get isModal(): boolean {
    return this.type === Constants.columnType.modal;
  }


  public get isRequestdDraftLink(): boolean {
    return this.type === Constants.columnType.requestedDraftLink;
  }

   isStringss(value): boolean{
    if(typeof value === 'string' && !value.includes('xml')){
      return true;
    }
    else{
      return false;
    }
  }

  public get isThumbnail(): boolean {
    return this.type === Constants.columnType.thumbnail;
  }
  public get isdsvgThumbnail(): boolean {
    return this.type === Constants.columnType.dsvgthumbnail;
  }


  public set value(val: string) {
    if (val !== undefined && val !== null) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public get background() {
    return `url(${this.value})`;
  }

  public writeValue(val: string): void {
    this.value = val;
  }


  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange() {

    console.log(this.inputValue)
    this.triggerChange(this.inputValue);
  }

  oncolorChange(event){
    console.log("vall>>",event)
    if(event != null && event != undefined){
      this.triggerChange(event);
    }

  }
  public onClick(){
    this.triggerChange("clickData");
  }
  public openModalLink(){
    this.triggerChange(this.inputValue);
  }

  public copyLink(){
    this.triggerChange(this.inputValue);
  }
}

