export class ColumnType {
    static readonly text = 'text';
    static readonly date = 'date';
    static readonly checkbox = 'checkbox';
    static readonly select = 'select';
    static readonly nullableselct = 'nullableselct';
    static readonly daterange = 'daterange';
    static readonly status = 'status';
    static readonly link = 'link';
    static readonly modal = 'modal';
    static readonly sharelink = 'sharelink';
    static readonly spotlightlink = 'spotlightlink'
    static readonly draftlink = 'draftlink'
    static readonly requestedDraftLink = 'requestedDraftLink'
    static readonly icon = 'icon';
    static readonly dicon = 'dicon';
    static readonly thumbnail = 'thumbnail';
    static readonly dsvgthumbnail = 'dsvgthumbnail';
    static readonly color = 'color';
    static readonly nullablecheckbox = 'nullablecheckbox';
}

export class ActionEventName {
    static readonly makeActive = 'ACTIVE';
    static readonly makeInactive = 'INACTIVE';
    static readonly  sendReminder= 'SENDREMINDER';
    static readonly deleteStory = 'DELETESTORY';
    static readonly internaltags = 'INTERNALTAGS';
    static readonly turnPublicOn = 'TURN PUBLIC ON';
    static readonly turnPublicOff = 'TURN PUBLIC OFF';
    static readonly turnInternalOff = 'TURN INTERNAL OFF';
    static readonly turnInternalOn = 'TURN INTERNAL ON';
    static readonly approve = 'APPROVE';
    static readonly addNote = 'ADD NOTE'
    static readonly reject = 'REJECT';
    static readonly sendEmail = 'SEND_EMAIL';
    static readonly changeusername = 'CHANGE_USER_NAME';
    static readonly changeDisplayTitle = 'CHANGE_DISPLAY_TITLE';
    static readonly linkToCampaign = 'LINK_TO_CAMPAIGN';
    static readonly editAuthorDetails = 'EDIT_AUTHOR_DETAILS';
    static readonly getUniqueLInk = 'GET_UNIQUE_LINK'
    static readonly getUniqueVideoLInk = 'GET_UNIQUE_VIDEO_LINK'
    static readonly getAdvocayPageLink = 'GET_ADVOCACY_PAGE_LINK'
    static readonly show = 'SHOW';
    static readonly hide = 'HIDE';
    static readonly edit = 'EDIT';
    static readonly delete = 'DELETE';
    static readonly deletePrompt = 'DELETE PROMPT';
    static readonly view = 'VIEW';
    static readonly link = 'LINK';
    static readonly notify = 'NOTIFY';
    static readonly sendThankYouEmail = 'SEND THANK YOU EMAIL'
    static readonly addToDefaultTags = 'ADD TO DEFAULT TAGS';
    static readonly DeleteJob = 'DELETE JOB'
    static readonly UpdateJob = 'UPDATE JOB';
    static readonly DeleteScript = 'DELETE SCRIPT';
    static readonly UpdateScript = 'UPDATE SCRIPT';
}