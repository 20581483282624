import { StoryOutputApiModel } from '../models/api-models/output';

export interface StoryEntity {
    id: number;
    internalId: number;
    title: string;
    slug: string;
    author: string;
    leads: number;
    likes: number;
    players: number;
    published: Date;
    status: string;
    approver: string;
    approved: boolean;
    visible: boolean;
    active: boolean;
    public: boolean;
    internal: boolean;
    id_campaign: number;
    boost_requested: boolean;
    steps?:any
    is_internal_story_approved: any;
    published_to_draft: any;
    spotlight_only: boolean;
    notes_count?: any;
    is_video?:boolean;
    user_requested?: any;
}

export class StoryEntity {
    constructor(data?: StoryOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: StoryOutputApiModel) {
        this.id = data.id;
        this.internalId = data.internal_id;
        this.title = data.title;
        this.slug = data.slug;
        this.author = data.author;
        this.leads = data.lead_count;
        this.likes = data.like_count;
        this.players = data.player_count;
        this.published = data.published;
        this.status = data.status;
        this.approver = data.approver;
        this.approved = data.approved;
        this.visible = data.visible;
        this.active = data.active;
        this.public = data.public;
        this.internal = data.internal;
        this.id_campaign = data.id_campaign;
        this.boost_requested = data.boost_requested;
        this.is_internal_story_approved = data.is_internal_story_approved;
        this.published_to_draft = data.published_to_draft
        this.spotlight_only = data.spotlight_only
        this.notes_count = data.notes_count
        this.is_video = data.is_video
        this.user_requested = data?.user_requested
    }

}
