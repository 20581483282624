export interface TestimonialOutputApiModel {
    id: number;
    internal_id: number;
    id_campaign: number;
    title: string;
    slug: string;
    author: string;
    lead_count: number;
    like_count: number;
    player_count: number;
    published: Date;
    status: string;
    approver: string;
    approved: boolean;
    visible: boolean;
    active: boolean;
    public: boolean;
    internal: boolean;
    author_company?: any;
    author_email?: any;
    author_type?: any;
    job_title?: any;
}

export class TestimonialOutputApiModel {
    constructor(data?: Partial<TestimonialOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}


