import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetReportLeadsInputApiModel } from '../models/api-models/input';
import { ReportLeadsOutputApiModel } from '../models/api-models/output';

@Injectable()
export class ReportLeadsHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetReportLeadsInputApiModel) {
        return this.get(`/${companyId}/report/leads/?${queryData.query}&spotlight=0`).pipe(
            map((response: ResponsePaginationApiModel<ReportLeadsOutputApiModel>) => {
                return response;
            })
        );
    }

    public loadSpotlightLeads(companyId: number, queryData: GetReportLeadsInputApiModel) {
        return this.get(`/${companyId}/report/leads/?${queryData.query}&spotlight=1`).pipe(
            map((response: ResponsePaginationApiModel<ReportLeadsOutputApiModel>) => {
                return response;
            })
        );
    }

}
