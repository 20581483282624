export interface NudgesSettingOutputApiModel {
    id: number;
    name: string;
    text: string;
    config: NudgesConfigOutputApiModel;
    is_active: boolean;
    is_for_internal: boolean;
    is_for_public: boolean;
    order_cta?: any;
    show_author_information?: boolean;
    show_jobs?:boolean
    conditional_button_text?:any
    nudge_name?: any;
}

export interface NudgesConfigOutputApiModel {
    form: NudgesButtonFormOutputApiModel[];
    button_link: string;
    button_text: string;
    button_type: string;
    form_title: string;
    donate_button_content?: any;
    donate_button_script?: any;
    donate_button_text?: any;
    show_author_information?:boolean;
    show_jobs?:boolean;
    conditional_button_text?: any;
}

export interface NudgesButtonFormOutputApiModel {
    title: string;
    isEnabled: boolean;
    isRedirection: boolean;
    isRequiredInfo: boolean;
    redirectionLink: string;
    redirectionText: string;
    thankyouText: string;


}

export class NudgesSettingOutputApiModel {
    constructor(data?: Partial<NudgesSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}