
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreStoreModule } from './store/core';
import { environment } from 'src/environments/environment';
import { AnalyticsStoreModule } from './modules/analytics/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IntegrationBlankComponent } from '@modules/shared/components';
import { AuthorizedGuard } from '@framework/guards';
import { MetaStoryDetailComponent } from './seo/meta-story-detail/meta-story-detail.component';
import * as MetaPublicCompanyComps from './modules/company/pages/public/components';
import { PublicPage } from '@modules/company/pages/public/public.page';
import { LandingPage } from '@modules/company/pages/public-featured-page/public-featured.page';
import { CampaignPage } from '@modules/company/pages/public-campaign-page/public-campaign.page';
import { PublicProfilePage } from '@modules/user/pages/public/public-profile.page';
import { ConfirmEmailComponent } from '@modules/shared/components/commons/confirm-email/confirm-email.component';
import { AdminAccessGuard } from '@framework/guards/adminAccess.guard';
import { CopyVideoComponent } from './seo/copy-video-url/copy-video-url.component';
import { UniqueStoryDetailComponent } from './seo/unique-people-detail-page/unique-people-detail-page.component';
import { EmbedVideoComponent } from './seo/embed-video-url /embed-video-url.component';
import { DonateButtonScriptComponent } from './seo/donate-button-script/donate-button-script.component';

const routes: Routes = [
  {
    path: 'c/:slug',
    component: PublicPage,
    children: [
      {
        path: '',
        component: MetaPublicCompanyComps.StoriesComponent,
        data: {
          pageName: 'Company Public'
        }
      },
      {
        path: 'campaigns',
        component: MetaPublicCompanyComps.CampaignsComponent,
        data: {
          pageName: 'Company Campaign Tab'
        }
      },
      {
        path: 'featured',
        component: MetaPublicCompanyComps.LandingPageComponent,
        data: {
          pageName: 'Company Public Page Tab'
        }
      }
    ]
  },
  {
    path: 'c/:slug/featured/:pageSlug',
    component: LandingPage
  },
  {
    path: 'c/:slug/myspotlight/:pageSlug',
    component: LandingPage
  },
  {
    path: 'c/:slug/campaigns/:pageSlug',
    component: CampaignPage
  },
  {
    path: 'c/:companySlug/:idOrSlug',
    component: MetaStoryDetailComponent,
    data: {
      pageName: 'Story Page'
    }
  },

  {
    path: 'video/:idOrSlug/:companySlug',
    component: CopyVideoComponent,
    data: {
      pageName: 'Video Page'
    }
  },

  {
    path: 'video/embed/:idOrSlug',
    component: EmbedVideoComponent,
    data: {
      pageName: 'Embed Video Page'
    }
  },

  {
    path: 'video/:idOrSlug/:companySlug/:token/anonymous',
    component: CopyVideoComponent,
    data: {
      pageName: 'Video Page'
    }
  },
  {
    path: 'c/:companySlug/:idOrSlug/:token',
    component: MetaStoryDetailComponent,
    data: {
      pageName: 'Story Page with Token'
    }
  },
  {
    path: 'c/:companySlug/:idOrSlug/:storytoken/:tracking/unique/people',
    component: UniqueStoryDetailComponent,
    data: {
      pageName: 'Story Page with Token'
    }
  },

  {
    path: 'p/:slug',
    component: PublicProfilePage,
    data: {
      pageName: 'My Profile Public'
    }
  },
  {
    path: 'p/:idOrSlug',
    component: MetaStoryDetailComponent,
    data: {
      pageName: 'Story Page'
    }
  },
  {
    path: 'p/:idOrSlug/:token',
    component: MetaStoryDetailComponent,
    data: {
      pageName: 'Story Page with Token'
    }
  },
  {
    path: 'cta-action/:token',
    component: DonateButtonScriptComponent,
    data: {
      pageName: 'Donate Button'
    }
  },
  {

    path: '',
    loadChildren: () => import('./modules/story/story.module').then(m => m.StoryModule)
  },
  {
    path: 'company/slack', component: IntegrationBlankComponent
  },
  {
    path: 'user/confirm-email/:token', component: ConfirmEmailComponent
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/pages/private-featured-page/private-featured-page.module').then(m => m.FeaturedPageModule),
    canActivate: [AuthorizedGuard]
  },
  //To check vividly below phase
  {
    path: 'company',
    loadChildren: () => import('./modules/company/pages/private-campaign-page/private-campaign-page.module').then(m => m.CampaignPageModule),
    canActivate: [AuthorizedGuard]
  },

  {
    path: 'campaignToken',
    loadChildren: () => import('./modules/company/pages/private-campaign-page/private-campaign-page.module').then(m => m.CampaignPageModule),
  },
  // To check vividly below
  {
    path: 'spotlightUnique',
    loadChildren: () => import('./modules/company/pages/private-featured-page/private-featured-page.module').then(m => m.FeaturedPageModule)
  },



  {
    path: 'company/settings',
    loadChildren: () => import('./modules/company-admin/company-admin.module').then(m => m.CompanyAdminModule),
    canActivate: [AuthorizedGuard, AdminAccessGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/story/pages/private/private.module').then(m => m.StoryPageModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'stories',
    loadChildren: () => import('./modules/story/pages/private/private.module').then(m => m.StoryPageModule),
  },
  {
    path: 't/:companySlug',
    loadChildren: () => import('./modules/story/pages/story-create-inbound/story-inbound.module').then(m => m.StoryInboundModule)
  },
  {
    path: 'interview',
    loadChildren: () => import('./modules/story/pages/interview-style/interview-style-create.module').then(m => m.InterviewModule),
  },

  {
    path: 'interview/inbound',
    loadChildren: () => import('./modules/story/pages/interview-style-inbound/interview-style-inbound.module').then(m => m.InterviewInboundModule)
  },

  {
    path: 't/customize/interview',
    loadChildren: () => import('./modules/story/pages/customize-interview-style-inbound/customize-interview-style-inbound.module').then(m => m.CustomizeInterviewInboundModule)
  },



  {
    path: 'advocacy/:companySlug/:idOrSlug/:token',
    loadChildren: () => import('./modules/story/pages/custom-advocacy/custom-advocacy.module').then(m => m.CustomAdvocacyModule)
  },
  {
    path: 'advocacy/:storytoken/:companySlug/:idOrSlug/:token',
    loadChildren: () => import('./modules/story/pages/custom-advocacy/custom-advocacy.module').then(m => m.CustomAdvocacyModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/story/pages/private/private.module').then(m => m.StoryPageModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'common',
    loadChildren: () => import('./modules/common/common.module').then(m => m.CommonModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./modules/user/pages/directory/directory.module').then(m => m.DirectoryPageModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthorizedGuard]
  },
  {
    path: '**',
    redirectTo: 'company'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    CoreStoreModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AnalyticsStoreModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
