import { TestimonialSettingOutputApiModel } from "../models/api-models/output";

export interface TestimonialSettingEntity {
    settingData: TestimonialSettingDataEntity;
}

export interface TestimonialSettingDataEntity {
    thankyouPage: ThankyouPageEntity;
    questions: QuestionsPageEntity[];
}

export interface ThankyouPageEntity {
    imageType: string;
    imageUrl: string;
    profileId: number;
    title: string;
    content: string;
    buttons: ThankyouButtonEntity[];
}

export class ThankyouPageEntity {
    constructor() {
        this.imageType = null;
        this.imageUrl = null;
        this.profileId = null;
        this.title = null;
        this.content = null;
        this.buttons = new Array<ThankyouButtonEntity>();
    }
}

export interface QuestionsPageEntity {
    questionId: number;
    title: string;
    isSelected: boolean;
}

export interface ThankyouButtonEntity {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export class TestimonialSettingEntity {
    constructor(data?: TestimonialSettingOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: TestimonialSettingOutputApiModel) {
        if (Object.keys(data.config).length) {
            this.settingData = {
                thankyouPage: {
                    imageType: data.config.thank_you_page.type,
                    imageUrl: data.config.thank_you_page.image,
                    profileId: data.config.thank_you_page.profile_id,
                    title: data.config.thank_you_page.title,
                    content: data.config.thank_you_page.content,
                    buttons: data.config.thank_you_page.buttons.map(button => {
                        return {
                            label: button.text,
                            url: button.url,
                            foreground: button.color,
                            background: button.background,
                            isSelected: button.isActive
                        }
                    })
                },
                questions: data.config.questions.map(question => {
                    return {
                        questionId: question.id,
                        title: question.text,
                        isSelected: question.isActive
                    }
                })
            }
        }
    }
}