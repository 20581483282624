import { Action } from '@ngrx/store';

import { StoryOutputApiModel } from '../../models/api-models/output';
import { CreateStoryInputApiModel, SubmitCtaInputApiModel, GetStoriesInputApiModel, UpdateMySettingStoryInputApiModel, SearchStoryInputApiModel } from '../../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { LikeStoryTagInputApiModel } from '../models/like-story-tag-input.api-model';

export enum ActionTypes {

  DO_NOTHING = "[Story] Do Nothing",

  LOAD_BY_COMPANY = "[Story] Load By Company",
  LOAD_BY_USER = "[Story] Load By User",
  LOAD_FOR_MY_SETTING = "[Story] Load For My Setting",

  LOAD_SPOTLIGHT_FREQUENT_TAGS = "[Story] Load Spotlight Frequent Tags",
  LOAD_SPOTLIGHT_FREQUENT_TAGS_SUCCESS = "[Story] Load Spotlight Frequent Tags Success",
  LOAD_SPOTLIGHT_FREQUENT_TAGS_FAIL = "[Story] Load Spotlight Frequent Tags Fail",

  LOAD_SUCCESS = "[Story] Load Success",
  LOAD_FAILURE = "[Story] Load Failure",

  LOAD_DETAIL = "[Story] Load Detail",
  LOAD_DETAIL_SUCCESS = "[Story] Load Detail Success",
  LOAD_DETAIL_FAILURE = "[Story] Load Detail Failure",

  LOAD_VIDEO = "[Story] Load Video",
  LOAD_VIDEO_SUCCESS = "[Story] Load Video Success",
  LOAD_VIDEO_FAILURE = "[Story] Load Video Failure",

  LOAD_EDIT = "[Story] Load Edit",
  LOAD_EDIT_SUCCESS = "[Story] Load Edit Success",
  LOAD_EDIT_FAILURE = "[Story] Load Edit Failure",

  CREATE = "[Story] Create",
  CREATE_SUCCESS = "[Story] Create Success",
  CREATE_FAILURE = "[Story] Create Failure",

  UPDATE = "[Story] Update",
  UPDATE_SUCCESS = "[Story] Update Success",
  UPDATE_FAILURE = "[Story] Update Failure",

  UPDATE_MY_SETTING_STORY = "[Story] Update My Setting Story",
  UPDATE_MY_SETTING_STORY_SUCCESS = "[Story] Update My Setting Story Success",
  UPDATE_MY_SETTING_STORY_FAILURE = "[Story] Update My Setting Story Failure",

  CREATE_INBOUND = "[Story] Create Inbound",

  PUBLISH = "[Story] Publish",
  PUBLISH_SUCCESS = "[Story] Publish Success",
  PUBLISH_FAILURE = "[Story] Publish Failure",

  DELETE = "[Story] Delete",
  DELETE_SUCCESS = "[Story] Delete Success",
  DELETE_FAILURE = "[Story] Delete Failure",

  DELETE_A_STORY = "[Story] Delete a Story",
  DELETE_A_STORY_SUCCESS = "[Story] Delete a Story Success",
  DELETE_A_STORY_FAILURE = "[Story] Delete a Story Failure",

  DELETES = "[Story] Deletes",
  DELETES_SUCCESS = "[Story] Deletes Success",
  DELETES_FAILURE = "[Story] Deletes Failure",

  SUBMIT_CTA = "[Story] Submit cta",
  SUBMIT_CTA_SUCCESS = "[Story] Submit cta Success",
  SUBMIT_CTA_FAILURE = "[Story] Submit cta Failure",

  VIEW = "[Story] View",
  VIEW_SUCCESS = "[Story] View Success",
  VIEW_FAILURE = "[Story] View Failure",

  SELECT = "[Story] Select",
  RESET_STORY_EDIT_DATA = "[Story] Reset Story Edit Data",
  RESET_STATE = "[Story] Reset State",

  SET_EMBEB_MODE_STATUS = "[Story] Set Embed Mode Status",

  LIKE_STORY_TAG = "[Story] Like Story Tag",
  LIKE_STORY_TAG_SUCCESS = "[Story] Like Story Tag Success",
  LIKE_STORY_TAG_FAILURE = "[Story] Like Story Tag Failure",

  SEND_EMAILS_INTERNAL_EXTERNAL = "[Story] Send Emails To Internal And External",
  SEND_EMAILS_INTERNAL_EXTERNAL_SUCCESS = "[Story] Send Emails To Internal And External Success",
  SEND_EMAILS_INTERNAL_EXTERNAL_FAILURE = "[Story] Send Emails To Internal And External Failure",


  CREATE_SEO = "[Story] Create Seo",
  CREATE_SEO_SUCCESS = "[Story] Create Seo Success",
  CREATE_SEO_FAILURE = "[Story] Create Seo Failure",

  LOAD_SEO = "[Story] Load Seo",
  LOAD_SEO_SUCCESS = "[Story] Load Seo Success",
  LOAD_SEO_FAILURE = "[Story] Load Seo Failure",
  
  ADD_TRACKING = "[Story] Add Tracking",
  ADD_TRACKING_SUCCESS = "[Story] Add Tracking Success",
  ADD_TRACKING_FAILURE = "[Story] Add Tracking Failure",

  ADD_PERSONALIZE_TRACK = "[Story] Add Personalize Tracking",
  ADD_PERSONALIZE_TRACK_SUCCESS = "[Story] Add Personalize Tracking Success",
  ADD_PERSONALIZE_TRACK_FAILURE = "[Story] Add Personalize Tracking Failure",

  ADD_TRACK_HIT = "[Story] Add Track Hit",
  ADD_TRACK_HIT_SUCCESS = "[Story]  Add Track Hit Success",
  ADD_TRACK_HIT_FAILURE = "[Story]  Add Track Hit Failure",

  GET_TRACK_HIT = "[Story] Get Track Hit",
  GET_TRACK_HIT_SUCCESS = "[Story]  Get Track Hit Success",
  GET_TRACK_HIT_FAILURE = "[Story]  Get Track Hit Failure",


  REVIEW_A_STORY = "[Story] Give a Review",
  REVIEW_A_STORY_SUCCESS = "[Story]  Give a Review Success",
  REVIEW_A_STORY_FAILURE = "[Story]  Give a Review Failure",


  CHANGE_USER_TITLE = "[Story] Change User Title",
  CHANGE_USER_TITLE_SUCCESS = "[Story]  Change User Title Success",
  CHANGE_USER_TITLE_FAILURE = "[Story]  Change User Title Failure",


  GET_PERSONALIZE_TRACK_HIT = "[Story] Get Personalize Track Hit",
  GET_PERSONALIZE_TRACK_HIT_SUCCESS = "[Story]  Get Personalize Track Hit Success",
  GET_PERSONALIZE_TRACK_HIT_FAILURE = "[Story]  Get Personalize Track Hit Failure",

  LOAD_DETAIL_CAMPAIGN_STORY_TOKEN = "[Story] Load Detail Campaign Story Token",

  LOAD_DETAIL_SPOTLIGHT_STORY_TOKEN = "[Story] Load Detail Spotlight Story Token",


}

export class LoadByCompany implements Action {
  readonly type = ActionTypes.LOAD_BY_COMPANY;

  constructor(public queryData: SearchStoryInputApiModel) { }
}

export class LoadByUser implements Action {
  readonly type = ActionTypes.LOAD_BY_USER;

  constructor(public queryData: GetStoriesInputApiModel) { }
}

export class LoadForMySetting implements Action {
  readonly type = ActionTypes.LOAD_FOR_MY_SETTING;

  constructor(public queryData: GetStoriesInputApiModel) { }
}

export class changeUserTitle implements Action {
  readonly type = ActionTypes.CHANGE_USER_TITLE;

  constructor(public companyId:any,public uId:any,public payload:any) { }
}

export class changeUserTitleSuccess implements Action {
  readonly type = ActionTypes.CHANGE_USER_TITLE_SUCCESS;
}

export class changeUserTitleFailure implements Action {
  readonly type = ActionTypes.CHANGE_USER_TITLE_FAILURE;

}


export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public response: ResponsePaginationApiModel<StoryOutputApiModel>) { }
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public error: any) { }
}

export class LoadDetail implements Action {
  readonly type = ActionTypes.LOAD_DETAIL;
  constructor(public storyIdOrSlug: number | string, public token?: any, public isPublic?:boolean, public campaign?: any, public spotlightId?: any) { };
}

export class LoadDetailSuccess implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SUCCESS;
  constructor(public data: StoryOutputApiModel) { }
}


export class LoadDetailFailure implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_FAILURE;
  constructor(public error: any) { }
}


export class LoadSpotlightFrequentTags implements Action {
  readonly type = ActionTypes.LOAD_SPOTLIGHT_FREQUENT_TAGS;
  constructor(public queryData: SearchStoryInputApiModel) { }
}

export class LoadSpotlightFrequentTagsSuccess implements Action {
  readonly type = ActionTypes.LOAD_SPOTLIGHT_FREQUENT_TAGS_SUCCESS;
  constructor(public data: any) { }
}


export class LoadSpotlightFrequentTagsFailure implements Action {
  readonly type = ActionTypes.LOAD_SPOTLIGHT_FREQUENT_TAGS_FAIL;
  constructor(public error: any) { }
}



export class LoadVideo implements Action {
  readonly type = ActionTypes.LOAD_VIDEO;
  constructor(public storyIdOrSlug: number | string, public companyId: any, public token: any) { };
}

export class LoadVideoSuccess implements Action {
  readonly type = ActionTypes.LOAD_VIDEO_SUCCESS;
  constructor(public data: any) { }
}


export class LoadVideoFailure implements Action {
  readonly type = ActionTypes.LOAD_VIDEO_FAILURE;
  constructor(public error: any){}

}

export class LoadCampaignStoryWithTokenDetail implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_CAMPAIGN_STORY_TOKEN;
  constructor(public storyIdOrSlug: number | string, public token?: any, public campaignid?:any) { };
}

export class LoadSpotlightStoryWithTokenDetail implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SPOTLIGHT_STORY_TOKEN;
  constructor(public storyIdOrSlug: number | string, public token?: any, public spotlightid?:any) { };
}

export class LoadEdit implements Action {
  readonly type = ActionTypes.LOAD_EDIT;
  constructor(public storyId: number) { };
}

export class LoadEditSuccess implements Action {
  readonly type = ActionTypes.LOAD_EDIT_SUCCESS;
  constructor(public data: StoryOutputApiModel) { }
}

export class LoadEditFailure implements Action {
  readonly type = ActionTypes.LOAD_EDIT_FAILURE;
  constructor(public error: any) { }
}

export class Create implements Action {
  readonly type = ActionTypes.CREATE;
  constructor(public data: CreateStoryInputApiModel) { }
}

export class CreateSuccess implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public data: StoryOutputApiModel) { }
}

export class CreateFailure implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public data: CreateStoryInputApiModel, public error: any) { }
}

export class Update implements Action {
  readonly type = ActionTypes.UPDATE;
  constructor(public id: number, public data: any) { }
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public data: StoryOutputApiModel) { }
}

export class UpdateFailure implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public data: any, public error: any) { }
}

export class UpdateMySettingStory implements Action {
  readonly type = ActionTypes.UPDATE_MY_SETTING_STORY;
  constructor(public id: number, public data: UpdateMySettingStoryInputApiModel) { }
}

export class UpdateMySettingStorySuccess implements Action {
  readonly type = ActionTypes.UPDATE_MY_SETTING_STORY_SUCCESS;
  constructor(public data: StoryOutputApiModel) { }
}

export class UpdateMySettingStoryFailure implements Action {
  readonly type = ActionTypes.UPDATE_MY_SETTING_STORY_FAILURE;
  constructor(public error: any) { }
}

export class SendEamilInternalExternal implements Action {
  readonly type = ActionTypes.SEND_EMAILS_INTERNAL_EXTERNAL;
  constructor(public payload:any) { }
}

export class SendEamilInternalExternalSuccess implements Action {
  readonly type = ActionTypes.SEND_EMAILS_INTERNAL_EXTERNAL_SUCCESS;
}

export class SendEamilInternalExternalFailure implements Action {
  readonly type = ActionTypes.SEND_EMAILS_INTERNAL_EXTERNAL_FAILURE;
}

export class DoNothing implements Action {
  readonly type = ActionTypes.DO_NOTHING
  constructor() { }
}

export class CreateInbound implements Action {
  readonly type = ActionTypes.CREATE_INBOUND;
  constructor(public data: CreateStoryInputApiModel) { }
}

export class Publish implements Action {
  readonly type = ActionTypes.PUBLISH;

  constructor(public storyId: number) { }
}

export class PublishSuccess implements Action {
  readonly type = ActionTypes.PUBLISH_SUCCESS;
  
  constructor(public data: any) { }
}

export class PublishFailure implements Action {
  readonly type = ActionTypes.PUBLISH_FAILURE;

  constructor(public error: any) { }
}

export class Delete implements Action {
  readonly type = ActionTypes.DELETE;

  constructor(public storyId: number) { }
}

export class DeleteSuccess implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;

  constructor(public error: any) { }
}

export class Deletes implements Action {
  readonly type = ActionTypes.DELETES;

  constructor(public storyIds: number[]) { }
}

export class DeletesSuccess implements Action {
  readonly type = ActionTypes.DELETES_SUCCESS;
}

export class DeletesFailure implements Action {
  readonly type = ActionTypes.DELETES_FAILURE;

  constructor(public error: any) { }
}

export class SubmitCta implements Action {
  readonly type = ActionTypes.SUBMIT_CTA;

  constructor(public storyId: number, public payload: Partial<SubmitCtaInputApiModel>) { }
}

export class SubmitCtaSuccess implements Action {
  readonly type = ActionTypes.SUBMIT_CTA_SUCCESS;
}

export class SubmitCtaFailure implements Action {
  readonly type = ActionTypes.SUBMIT_CTA_FAILURE;

  constructor(public error: any) { }
}

export class View implements Action {
  readonly type = ActionTypes.VIEW;

  constructor(public storyId: number) { }
}

export class ViewSuccess implements Action {
  readonly type = ActionTypes.VIEW_SUCCESS;
}

export class ViewFailure implements Action {
  readonly type = ActionTypes.VIEW_FAILURE;

  constructor(public error: any) { }
}

export class Select implements Action {
  readonly type = ActionTypes.SELECT;

  constructor(public idOrSlug: number | string,public isPublic?:boolean, public campaign?: any, public spotlightId?: any) { }
}

export class ResetStoryEditData implements Action {
  readonly type = ActionTypes.RESET_STORY_EDIT_DATA;
}

export class SetEmbedModeStatus implements Action {
  readonly type = ActionTypes.SET_EMBEB_MODE_STATUS;

  constructor(public isEmbedMode: boolean) { }
}

export class ResetState implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class LikeStoryTag implements Action {
  readonly type = ActionTypes.LIKE_STORY_TAG;

  constructor(public queryData: LikeStoryTagInputApiModel) {
  }
}

export class LikeStoryTagSuccess implements Action {
  readonly type = ActionTypes.LIKE_STORY_TAG_SUCCESS;
}

export class LikeStoryTagFailure implements Action {
  readonly type = ActionTypes.LIKE_STORY_TAG_FAILURE;

  constructor(public error: any) { }
}

export class CreateSeo implements Action {
  readonly type = ActionTypes.CREATE_SEO;
  constructor(public data: any,public storyId: any) { }
}

export class CreateSeoSuccess implements Action {
  readonly type = ActionTypes.CREATE_SEO_SUCCESS;
  constructor(public data: any) { }
}

export class CreateSeoFailure implements Action {
  readonly type = ActionTypes.CREATE_SEO_FAILURE;
  constructor(public data: any, public error: any) { }
}

export class LoadSeo implements Action {
  readonly type = ActionTypes.LOAD_SEO;
  constructor(public storyId: number) { };
}

export class LoadSeoSuccess implements Action {
  readonly type = ActionTypes.LOAD_SEO_SUCCESS;
  constructor(public data: any) { }
}

export class LoadSeoFailure implements Action {
  readonly type = ActionTypes.LOAD_SEO_FAILURE;
  constructor(public error: any) { }
}

export class AddTracking implements Action {
  readonly type = ActionTypes.ADD_TRACKING;
  constructor(public payload:any) { };
}

export class AddTrackingSuccess implements Action {
  readonly type = ActionTypes.ADD_TRACKING_SUCCESS;
  constructor(public data: any) { }
}

export class AddTrackingFailure implements Action {
  readonly type = ActionTypes.ADD_TRACKING_FAILURE;
  constructor(public error: any) { }
}

export class AddTrackHit implements Action {
  readonly type = ActionTypes.ADD_TRACK_HIT;
  constructor(public trackingid:any) { };
}

export class AddTrackHitSuccess implements Action {
  readonly type = ActionTypes.ADD_TRACK_HIT_SUCCESS;
  constructor(public data: any) { }
}

export class AddTrackHitFailure implements Action {
  readonly type = ActionTypes.ADD_TRACKING_FAILURE;
  constructor(public error: any) { }
}


export class AddPersonalizeTrackHit implements Action {
  readonly type = ActionTypes.ADD_PERSONALIZE_TRACK;
  constructor(public payload:any) { };
}

export class AddPersonalizeTrackHitSuccess implements Action {
  readonly type = ActionTypes.ADD_PERSONALIZE_TRACK_SUCCESS;
  constructor(public data: any) { }
}

export class AddPersonalizeTrackHitFailure implements Action {
  readonly type = ActionTypes.ADD_PERSONALIZE_TRACK_FAILURE;
  constructor(public error: any) { }
}


export class GetTrackHit implements Action {
  readonly type = ActionTypes.GET_TRACK_HIT;
  constructor (public companyId : any){}
}

export class GetTrackHitSuccess implements Action {
  readonly type = ActionTypes.GET_TRACK_HIT_SUCCESS;
  constructor(public data: any) { }
}

export class GetTrackHitFailure implements Action {
  readonly type = ActionTypes.GET_TRACK_HIT_FAILURE;
  constructor(public error: any) { }
}

export class GetPersonalizeTrackHit implements Action {
  readonly type = ActionTypes.GET_PERSONALIZE_TRACK_HIT;
  constructor (public uniqueToken : any, public isInbound?:boolean){}
}

export class GetPersonalizeTrackHitSuccess implements Action {
  readonly type = ActionTypes.GET_PERSONALIZE_TRACK_HIT_SUCCESS;
  constructor(public data: any) { }
}

export class GetPersonalizeTrackHitFailure implements Action {
  readonly type = ActionTypes.GET_PERSONALIZE_TRACK_HIT_FAILURE;
  constructor(public error: any) { }
}


export class DeleteaStory implements Action {
  readonly type = ActionTypes.DELETE_A_STORY;

  constructor(public storyid: any) { }
}

export class DeleteaStorySuccess implements Action {
  readonly type = ActionTypes.DELETE_A_STORY_SUCCESS;
}

export class DeleteaStoryFailure implements Action {
  readonly type = ActionTypes.DELETE_A_STORY_FAILURE;
  constructor(public error: any) { }
}

export class ReviewaStory implements Action {
  readonly type = ActionTypes.REVIEW_A_STORY;

  constructor(public companyId: any,public storyId, public queryParams) { }
}

export class ReviewaStorySuccess implements Action {
  readonly type = ActionTypes.REVIEW_A_STORY_SUCCESS;
  constructor(public data: any) { }
}

export class ReviewaStoryFailure implements Action {
  readonly type = ActionTypes.REVIEW_A_STORY_FAILURE;
}






export type Actions =
  LoadByCompany | LoadByUser | LoadForMySetting | LoadSuccess | LoadFailure
  | LoadDetail | LoadDetailSuccess | LoadDetailFailure
  | LoadEdit | LoadEditSuccess | LoadEditFailure
  | Create | CreateSuccess | CreateFailure
  | Update | UpdateSuccess | UpdateFailure
  | UpdateMySettingStory | UpdateMySettingStorySuccess | UpdateMySettingStoryFailure
  | DoNothing | CreateInbound
  | Publish | PublishSuccess | PublishFailure
  | Delete | DeleteSuccess | DeleteFailure
  | Deletes | DeletesSuccess | DeletesFailure
  | Select | ResetStoryEditData | SetEmbedModeStatus | ResetState 
  | View | ViewSuccess | ViewFailure | CreateSeo | CreateSeoSuccess 
  | CreateSeoFailure | LoadSeo | LoadSeoSuccess | LoadSeoFailure
  | AddTracking | AddTrackingSuccess | AddTrackingFailure
  | AddTrackHit | AddTrackHitSuccess | AddTrackHitFailure 
  | GetTrackHit | GetTrackHitSuccess | GetTrackHitFailure
  | DeleteaStory | DeleteaStorySuccess | DeleteaStoryFailure
  | LoadSpotlightStoryWithTokenDetail | LoadCampaignStoryWithTokenDetail
  | LoadVideo | LoadVideoSuccess | LoadVideoFailure
  | LoadSpotlightFrequentTags | LoadSpotlightFrequentTagsFailure | LoadSpotlightFrequentTagsSuccess
  | AddPersonalizeTrackHit | AddPersonalizeTrackHitSuccess  | AddPersonalizeTrackHitFailure
  | GetPersonalizeTrackHit | GetPersonalizeTrackHitSuccess | GetPersonalizeTrackHitFailure
  | ReviewaStory | ReviewaStorySuccess | ReviewaStoryFailure