import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetTestimonialInputApiModel } from '../models/api-models/input';
import { TestimonialOutputApiModel } from '../models/api-models/output';

@Injectable()
export class TestimonialHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetTestimonialInputApiModel) {
        return this.get(`/${companyId}/setting/testimonials/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<TestimonialOutputApiModel>) => {
                return response;
            })
        );
    }

    public makeActive(companyId: number, adminId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/testimonials/active/?aid=${adminId}&ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInactive(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/testimonials/active/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makePublic(companyId: number, adminId: number, ids: number[],payload:any) {
        const option = payload.option!=3 ? '&option=' + payload.option : '';
        return this.post(`/${companyId}/setting/testimonials/public/?aid=${adminId}&ids=${ids.join(',')}${option}&enable_advocacy=${payload.enable_advocacy}&internal_advocacy=${payload.internal_advocacy}&public_advocacy=${payload.public_advocacy}`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInternal(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/testimonials/public/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public approve(companyId: number, adminId: number, ids: number[],sendemail,sendTo,payload) {
        if(sendemail){
            let obj = sendTo !=3 ? 'send='+sendTo : ''
            return this.post(`/${companyId}/setting/testimonials/approve/?aid=${adminId}&ids=${ids.join(',')}&sm=1&${obj}`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );  
        }
        else{

            return this.post(`/${companyId}/setting/testimonials/approve/?aid=${adminId}&ids=${ids.join(',')}&sm=0`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
       
    }

    public reject(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/testimonials/approve/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeVisible(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/testimonials/visible/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInvisible(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/testimonials/visible/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public linkToCampaign(companyId: number, campaignId: number, id: number) {
        return this.post(`/${companyId}/setting/testimonials/active/?id=${id}&cid=${campaignId}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
