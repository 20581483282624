import { Action } from '@ngrx/store';

import { UserOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetUserInputApiModel, CreateUserInputApiModel, CreateUserCSVInputApiModel, SendEmailInputApiModel } from '@modules/company-admin/store/models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin User] Load',
    LOAD_SUCCESS = '[Company Admin User] Load Success',
    LOAD_FAIL = '[Company Admin User] Load Fail',

    SEND_EMAIL = '[Company Admin User] Send Email',
    SEND_EMAIL_SUCCESS = '[Company Admin User] Send Email Success',
    SEND_EMAIL_FAIL = '[Company Admin User] Send Email Fail',

    CREATE = '[Company Admin User] Create',
    CREATE_SUCCESS = '[Company Admin User] Create Success',
    CREATE_FAIL = '[Company Admin User] Create Fail',

    CREATE_CSV = '[Company Admin User] Create CSV',
    CREATE_CSV_SUCCESS = '[Company Admin User] Create CSV Success',
    CREATE_CSV_FAIL = '[Company Admin User] Create CSV Fail',

    ENABLE_ACCESS = '[Company Admin User] Enable Access',
    ENABLE_ACCESS_SUCCESS = '[Company Admin User] Enable Access Success',
    ENABLE_ACCESS_FAIL = '[Company Admin User] Enable Access Fail',

    DISABLE_ACCESS = '[Company Admin User] Disable Access',
    DISABLE_ACCESS_SUCCESS = '[Company Admin User] Disable Access Success',
    DISABLE_ACCESS_FAIL = '[Company Admin User] Disable Access Fail',

    MAKE_ADMIN = '[Company Admin User] Make Admin',
    MAKE_ADMIN_SUCCESS = '[Company Admin User] Make Admin Success',
    MAKE_ADMIN_FAIL = '[Company Admin User] Make Admin Fail',

    MAKE_REGULAR = '[Company Admin User] Make Regular',
    MAKE_REGULAR_SUCCESS = '[Company Admin User] Make Regular Success',
    MAKE_REGULAR_FAIL = '[Company Admin User] Make Regular Fail',

    CHANGE_ROLES = '[Company Admin User] Change Roles',
    CHANGE_ROLES_SUCCESS = '[Company Admin User] Change Roles Success',
    CHANGE_ROLES_FAIL = '[Company Admin User] Change Roles Fail',

    CHANGE_USER_NAME = '[Company Admin User] Change Username',
    CHANGE_USER_NAME_SUCCESS = '[Company Admin User] Change Username Success',
    CHANGE_USER_NAME_FAIL = '[Company Admin User] Change Username Fail',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetUserInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<UserOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class SendEmail implements Action {
    readonly type = ActionTypes.SEND_EMAIL;
    constructor(public companyId: number, public queryData: SendEmailInputApiModel) { }
}

export class SendEmailSuccess implements Action {
    readonly type = ActionTypes.SEND_EMAIL_SUCCESS;
}

export class SendEmailFail implements Action {
    readonly type = ActionTypes.SEND_EMAIL_FAIL;
    constructor(public error: any) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(public companyId: number, public queryData: any) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(public response:any) {
    }
}

export class CreateFail implements Action {
    readonly type = ActionTypes.CREATE_FAIL;

    constructor(public error: any) {
    }
}

export class CreateCSV implements Action {
    readonly type = ActionTypes.CREATE_CSV;

    constructor(public companyId: number, public queryData: CreateUserCSVInputApiModel) { }
}

export class CreateCSVSuccess implements Action {
    readonly type = ActionTypes.CREATE_CSV_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<UserOutputApiModel>) {
    }
}

export class CreateCSVFail implements Action {
    readonly type = ActionTypes.CREATE_CSV_FAIL;

    constructor(public error: any) {
    }
}

export class EnableAccess implements Action {
    readonly type = ActionTypes.ENABLE_ACCESS;

    constructor(public companyId: number, public ids: number[]) { }
}

export class EnableAccessSuccess implements Action {
    readonly type = ActionTypes.ENABLE_ACCESS_SUCCESS;
}

export class EnableAccessFail implements Action {
    readonly type = ActionTypes.ENABLE_ACCESS_FAIL;

    constructor(public error: any) { }
}

export class DisableAccess implements Action {
    readonly type = ActionTypes.DISABLE_ACCESS;

    constructor(public companyId: number, public ids: number[]) { }
}

export class DisableAccessSuccess implements Action {
    readonly type = ActionTypes.DISABLE_ACCESS_SUCCESS;
}

export class DisableAccessFail implements Action {
    readonly type = ActionTypes.DISABLE_ACCESS_FAIL;

    constructor(public error: any) { }
}

export class MakeAdmin implements Action {
    readonly type = ActionTypes.MAKE_ADMIN;

    constructor(public companyId: number, public ids: number[]) { }
}

export class MakeAdminSuccess implements Action {
    readonly type = ActionTypes.MAKE_ADMIN_SUCCESS;
}

export class MakeAdminFail implements Action {
    readonly type = ActionTypes.MAKE_ADMIN_FAIL;

    constructor(public error: any) { }
}

export class MakeRegular implements Action {
    readonly type = ActionTypes.MAKE_REGULAR;

    constructor(public companyId: number, public ids: number[]) { }
}

export class MakeRegularSuccess implements Action {
    readonly type = ActionTypes.MAKE_REGULAR_SUCCESS;
}


export class MakeRegularFail implements Action {
    readonly type = ActionTypes.MAKE_REGULAR_FAIL;

    constructor(public error: any) { }
}

export class ChangeRoles implements Action {
    readonly type = ActionTypes.CHANGE_ROLES;

    constructor(public companyId: number, public ids: number[],public roleId: number) { }
}

export class ChangeRolesSuccess implements Action {
    readonly type = ActionTypes.CHANGE_ROLES_SUCCESS;
}

export class ChangeRolesFail implements Action {
    readonly type = ActionTypes.CHANGE_ROLES_FAIL;

    constructor(public error: any) { }
}
export class ChangeUsername implements Action {
    readonly type = ActionTypes.CHANGE_USER_NAME;
  
    constructor(public payload: any, public id:any) { }
  }
  
  export class ChangeUsernameSuccess implements Action {
    readonly type = ActionTypes.CHANGE_USER_NAME_SUCCESS;
  }
  
  export class ChangeUsernameFail implements Action {
    readonly type = ActionTypes.CHANGE_USER_NAME_FAIL;
  
    constructor(public error: any) { }
  }



export type Actions =
    Load | LoadSuccess | LoadFail |
    Create | CreateSuccess | CreateFail |
    CreateCSV | CreateCSVSuccess | CreateCSVFail |
    EnableAccess | EnableAccessSuccess | EnableAccessFail |
    DisableAccess | DisableAccessSuccess | DisableAccessFail |
    MakeAdmin | MakeAdminSuccess | MakeAdminFail |
    ChangeRoles | ChangeRolesSuccess | ChangeRolesFail |
    MakeRegular | MakeRegularSuccess | MakeRegularFail |
    ChangeUsername | ChangeUsernameSuccess | ChangeUsernameFail;; ;
