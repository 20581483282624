
<ng-container *ngIf="view_type == 'normal'">
  <div class="cta-box" [ngClass]="{'is-active': isShowToggleActive, 'is-bottom': isBottom, 'is-first': isFirst}"  *ngIf="isShowNudge">
    <div class="cta">
      <div>
        <div class="cta__inner">
          <div class="cta__header">
            <button class="btn btn-trans" (click)="toggleShowActive()" [hidden]="ctaStepActivated !== 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-collapse"></use>
              </svg>
            </button>
            <button class="btn btn-trans" (click)="closeStep()" [hidden]="ctaStepActivated === 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div class="cta__body" [formGroup]="ctaForm">
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 0}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <div class="cta-step__content text-center text-md-left">
                <div class="cta-step__text" *ngIf="storyDetail.cta.show_author_information" [innerHtml]="storyDetail.cta.text | safeHtml" [ngStyle]="{'color':text_color}"></div>
                <ng-container *ngIf="showJobslist.length">
                  <div class="jobs-list">
                    <div class="jobs-style" *ngFor="let item of showJobslist"><a  (click)="jobbHiits(item)">{{item.title}}</a><span class="addresss" *ngIf="item?.city || item?.state"> - {{item?.city}} <span *ngIf="item?.city && item?.state">,</span> {{item?.state}}</span></div>
                  </div>
                </ng-container>
                <div *ngIf="isShowNudgeButton" class="cta-step__more text-center text-md-right">
                  <a *ngIf="isNudgeBasic && storyDetail.cta.show_author_information" (click)="addCtaHit(storyDetail.cta.link)"   [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" target="_blank"
                    class="btn btn-pink min-width anim-pulse-cta">{{showbbuttontext}}</a>
                  <button *ngIf="isNudgeComplex" type="button"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="openStep1()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.conditional_button_text}}</button>
                    <button *ngIf="isNudgeDonation" type="button"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="openDonation()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.donate_button_text}}</button>
                </div>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 1}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <div class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">What are you interested in?</h5>
                <div class="form-group" *ngFor="let item of storyDetail.ctaFormConfigs; index as i">
                  <ng-container *ngIf="item.is_enabled">
                    <div class="custom-control custom-radio"
                    [ngClass]="{'invalid': !ctaForm.controls.interested.valid && ctaForm.controls.interested.dirty}">
                    <label [for]="'customRadio' + i" class="mb-0">
                      <input [id]="'customRadio' + i" [value]="item.title" formControlName="interested" type="radio"
                        class="custom-control-input" />
                      <span class="custom-control-label">{{item.title}}</span>
                    </label>
                  </div>
                  </ng-container>
    
                </div>
                <button (click)="openStep2()" class="btn btn-pink min-width">Continue</button>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 2}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <form [formGroup]="ctaForm" (ngSubmit)="ctaFormSubmit()" class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">How can we get in touch with you?</h5>
                <div class="form-group">
                  <input truncateString type="text" placeholder="Full name" class="form-control" formControlName="fullName"
                    [ngClass]="{'is-invalid': !ctaForm.controls.fullName.valid && ctaForm.controls.fullName.dirty}"
                    [valueTruncate]="ctaForm.controls.fullname?.value" (valueTruncateChange)="ctaForm.controls.fullname?.setValue($event)" />
                </div>
                <div class="form-group">
                  <input type="email" placeholder="Email" class="form-control" formControlName="email"
                    [ngClass]="{'is-invalid': !ctaForm.controls.email.valid && ctaForm.controls.email.dirty}" />
                </div>
                <button type="submit" class="btn btn-pink min-width">Continue</button>
              </form>
            </div>
            <div class="cta-step text-center text-md-left" [ngClass]="{'none': ctaStepActivated !== 3}"
              *ngIf="interestSelected">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <!--Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="interestSelected.isRedirection && interestSelected.link">
                <div class="form-group">
                  <a [href]="interestSelected.link" class="btn btn-pink min-width"
                    target="_blank">{{interestSelected.text}}</a>
                </div>
                <div class="text-smaller text-gray">
                  By clicking this link, you will be leaving GoodSeeker.com.
                  <br>
                  Security and privacy policies may be different than ours.</div>
              </div>
              <!--Don't Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="!(interestSelected.isRedirection && interestSelected.link)">
                <h5 class="mb-3">
                  {{interestSelected.thankYouText}}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <ng-container *ngIf="(isNudgeDonation && storyDetail.cta.show_author_information ) || (isNudgeBasic && storyDetail.cta.show_author_information) ||  (isNudgeComplex && storyDetail.cta.show_author_information) || (isNudgeJob && storyDetail.cta.show_author_information)">
        <div class="cta__button" style="color: white" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
          <h6>{{storyDetail.cta.button}}</h6>
          <button class="btn btn-trans ml-3" style="color: #fff">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button>
        </div>
      </ng-container>
  
      <ng-container *ngIf="(isNudgeDonation &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button"  style="color: white" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openDonation()">
          <h6>{{storyDetail.cta.button}}</h6>
          <!-- <button class="btn btn-trans ml-3">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button> -->
        </div>
      </ng-container>
      

      <ng-container *ngIf="(isNudgeComplex &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="openComplexForm()">
          <h6>{{storyDetail.cta.button}}</h6>
          <button class="btn btn-trans ml-3" style="color: #fff">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="(isNudgeBasic &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="addCtaHit(storyDetail.cta.link)">
          <h6>{{storyDetail.cta.button}}</h6>
          <!-- <button class="btn btn-trans ml-3">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button> -->
        </div>
      </ng-container>

      <ng-container *ngIf="(isNudgeJob &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color,'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
          <h6>{{storyDetail.cta.button}}</h6>
          <button class="btn btn-trans ml-3" style="color: #fff">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button>
        </div>
      </ng-container>
  
    </div>
  </div>
</ng-container>

<ng-container *ngIf="view_type == 'floating'">
  <div class="cta-box" [ngClass]="{'is-active': isShowToggleActive, 'is-bottom': isBottom, 'is-first': isFirst}"  *ngIf="isShowNudge">
    <div class="cta floating_body" [ngClass]="{'floating_mobile_body':isSmallScreen}" >
      <div>
        <div class="cta__inner">
          <div class="cta__header">
            <button class="btn btn-trans" (click)="toggleShowActive()" [hidden]="ctaStepActivated !== 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-collapse"></use>
              </svg>
            </button>
            <button class="btn btn-trans" (click)="closeStep()" [hidden]="ctaStepActivated === 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div class="cta__body" [formGroup]="ctaForm">
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 0}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <div class="cta-step__content text-center text-md-left">
                <div class="cta-step__text"  *ngIf="storyDetail.cta.show_author_information" [innerHtml]="storyDetail.cta.text | safeHtml" [ngStyle]="{'color':text_color}"></div>
                <ng-container *ngIf="showJobslist.length">
                  <div class="jobs-list">
                    <div class="jobs-style" *ngFor="let item of showJobslist"><a  (click)="jobbHiits(item)">{{item.title}}</a><span class="addresss" *ngIf="item?.city || item?.state"> - {{item?.city}} <span *ngIf="item?.city && item?.state">,</span> {{item?.state}}</span></div>
                  </div>
                </ng-container>
                <div *ngIf="isShowNudgeButton" class="cta-step__more text-center text-md-right">
                  <a *ngIf="isNudgeBasic && storyDetail.cta.show_author_information" (click)="addCtaHit(storyDetail.cta.link)"   [ngStyle]="{'background-color':button_color,  'color':button_text_color, 'border-color': button_color}" target="_blank"
                    class="btn btn-pink min-width anim-pulse-cta">{{showbbuttontext}}</a>
                  <button *ngIf="isNudgeComplex" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openStep1()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.conditional_button_text}}</button>
                    <button *ngIf="isNudgeDonation" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openDonation()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.donate_button_text}}</button>

                </div>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 1}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <div class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">What are you interested in?</h5>
                <div class="form-group" *ngFor="let item of storyDetail.ctaFormConfigs; index as i">
                  <ng-container *ngIf="item.is_enabled">
                    <div class="custom-control custom-radio"
                    [ngClass]="{'invalid': !ctaForm.controls.interested.valid && ctaForm.controls.interested.dirty}">
                    <label [for]="'customRadio' + i" class="mb-0">
                      <input [id]="'customRadio' + i" [value]="item.title" formControlName="interested" type="radio"
                        class="custom-control-input" />
                      <span class="custom-control-label">{{item.title}}</span>
                    </label>
                  </div>
                  </ng-container>
    
                </div>
                <button (click)="openStep2()" class="btn btn-pink min-width">Continue</button>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 2}">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <form [formGroup]="ctaForm" (ngSubmit)="ctaFormSubmit()" class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">How can we get in touch with you?</h5>
                <div class="form-group">
                  <input truncateString type="text" placeholder="Full name" class="form-control" formControlName="fullName"
                    [ngClass]="{'is-invalid': !ctaForm.controls.fullName.valid && ctaForm.controls.fullName.dirty}"
                    [valueTruncate]="ctaForm.controls.fullname?.value" (valueTruncateChange)="ctaForm.controls.fullname?.setValue($event)" />
                </div>
                <div class="form-group">
                  <input type="email" placeholder="Email" class="form-control" formControlName="email"
                    [ngClass]="{'is-invalid': !ctaForm.controls.email.valid && ctaForm.controls.email.dirty}" />
                </div>
                <button type="submit" class="btn btn-pink min-width">Continue</button>
              </form>
            </div>
            <div class="cta-step text-center text-md-left" [ngClass]="{'none': ctaStepActivated !== 3}"
              *ngIf="interestSelected">
              <div class="cta-step__avatar">
                <figure>
                  <gs-avatar classCss="avatar" isBackground="true"
                    [src]="storyDetail.author.avatar"></gs-avatar>
                  <figcaption>{{storyDetail.author.displayName}}</figcaption>
                </figure>
              </div>
              <!--Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="interestSelected.isRedirection && interestSelected.link">
                <div class="form-group">
                  <a [href]="interestSelected.link" class="btn btn-pink min-width"
                    target="_blank">{{interestSelected.text}}</a>
                </div>
                <div class="text-smaller text-gray">
                  By clicking this link, you will be leaving GoodSeeker.com.
                  <br>
                  Security and privacy policies may be different than ours.</div>
              </div>
              <!--Don't Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="!(interestSelected.isRedirection && interestSelected.link)">
                <h5 class="mb-3">
                  {{interestSelected.thankYouText}}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

  
    </div>
  </div>
  <ng-container *ngIf="(isNudgeDonation && storyDetail.cta.show_author_information ) || (isNudgeBasic && storyDetail.cta.show_author_information) ||  (isNudgeComplex &&  storyDetail.cta.show_author_information) || (isNudgeJob &&  storyDetail.cta.show_author_information)">
    <div class="cta__button floating_button"   [ngClass]="{'floating_mobile_button':isSmallScreen}" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
      <h6>{{storyDetail.cta.button}}</h6>
      <button class="btn btn-trans ml-3" style="color: #fff">
        <svg role="img">
          <use xlink:href="#icon-expand"></use>
        </svg>
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="(isNudgeDonation &&  !storyDetail.cta.show_author_information)">
    <div class="cta__button floating_button" style="color: white" [ngClass]="{'floating_mobile_button':isSmallScreen}"   [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openDonation()">
      <h6>{{storyDetail.cta.button}}</h6>
      <!-- <button class="btn btn-trans ml-3">
        <svg role="img">
          <use xlink:href="#icon-expand"></use>
        </svg>
      </button> -->
    </div>
  </ng-container>

  <ng-container *ngIf="(isNudgeBasic &&  !storyDetail.cta.show_author_information)">
    <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="addCtaHit(storyDetail.cta.link)">
      <h6>{{storyDetail.cta.button}}</h6>
      <!-- <button class="btn btn-trans ml-3">
        <svg role="img">
          <use xlink:href="#icon-expand"></use>
        </svg>
      </button> -->
    </div>
  </ng-container>

  <ng-container *ngIf="(isNudgeJob &&  !storyDetail.cta.show_author_information)">
    <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
      <h6>{{storyDetail.cta.button}}</h6>
      <button class="btn btn-trans ml-3">
        <svg role="img">
          <use xlink:href="#icon-expand"></use>
        </svg>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="(isNudgeComplex &&  !storyDetail.cta.show_author_information)">
    <div class="cta__button floating_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openComplexForm()">
      <h6>{{storyDetail.cta.button}}</h6>
      <button class="btn btn-trans ml-3">
        <svg role="img">
          <use xlink:href="#icon-expand"></use>
        </svg>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="view_type == 'sidebar'">
  <div class="cta-box" [ngClass]="{'is-active': isShowToggleActive, 'is-bottom': isBottom, 'is-first': isFirst}"  *ngIf="isShowNudge">
    <div class="cta sidebar_body">
      <div>
        <div class="cta__inner" [ngClass]="{'slide-left':isShowToggleActive, 'slide-right':!isShowToggleActive}">
          <div class="cta__header">
            <button class="btn btn-trans" (click)="toggleShowActive()" [hidden]="ctaStepActivated !== 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-collapse"></use>
              </svg>
            </button>
            <button class="btn btn-trans" (click)="closeStep()" [hidden]="ctaStepActivated === 0">
              <svg role="img" class="text-pink">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
          <div class="cta__body open_body" [formGroup]="ctaForm">
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 0}">

              <div class="cta-step__content text-center text-md-left">
                <div class="cta-step__text step_next_sidebar" [innerHtml]="storyDetail.cta.text | safeHtml" [ngStyle]="{'color':text_color}"></div>
                <ng-container *ngIf="showJobslist.length">
                  <div class="jobs-list">
                    <div class="jobs-style" *ngFor="let item of showJobslist"><a  (click)="jobbHiits(item)">{{item.title}}</a><span class="addresss" *ngIf="item?.city || item?.state"> - {{item?.city}} <span *ngIf="item?.city && item?.state">,</span> {{item?.state}}</span></div>
                  </div>
                </ng-container>
                <div *ngIf="isShowNudgeButton" class="cta-step__more text-center text-md-right">
                  <a *ngIf="isNudgeBasic && storyDetail.cta.show_author_information" (click)="addCtaHit(storyDetail.cta.link)"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" target="_blank"
                    class="btn btn-pink min-width anim-pulse-cta">{{showbbuttontext}}</a>
                  <button *ngIf="isNudgeComplex" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openStep1()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.conditional_button_text}}</button>
                    <button *ngIf="isNudgeDonation" type="button" [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}"  (click)="openDonation()"
                    class="btn btn-pink min-width anim-pulse-cta">{{storyDetail.cta.donate_button_text}}</button>
\
                </div>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 1}">
              <div class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">What are you interested in?</h5>
                <div class="form-group" *ngFor="let item of storyDetail.ctaFormConfigs; index as i">
                  <ng-container *ngIf="item.is_enabled">
                    <div class="custom-control custom-radio"
                    [ngClass]="{'invalid': !ctaForm.controls.interested.valid && ctaForm.controls.interested.dirty}">
                    <label [for]="'customRadio' + i" class="mb-0">
                      <input [id]="'customRadio' + i" [value]="item.title" formControlName="interested" type="radio"
                        class="custom-control-input" />
                      <span class="custom-control-label">{{item.title}}</span>
                    </label>
                  </div>
                  </ng-container>
    
                </div>
                <button (click)="openStep2()" class="btn btn-pink min-width">Continue</button>
              </div>
            </div>
            <div class="cta-step" [ngClass]="{'none': ctaStepActivated !== 2}">
              <form [formGroup]="ctaForm" (ngSubmit)="ctaFormSubmit()" class="cta-step__content text-center text-md-left">
                <h5 class="mb-3">How can we get in touch with you?</h5>
                <div class="form-group">
                  <input truncateString type="text" placeholder="Full name" class="form-control" formControlName="fullName"
                    [ngClass]="{'is-invalid': !ctaForm.controls.fullName.valid && ctaForm.controls.fullName.dirty}"
                    [valueTruncate]="ctaForm.controls.fullname?.value" (valueTruncateChange)="ctaForm.controls.fullname?.setValue($event)" />
                </div>
                <div class="form-group">
                  <input type="email" placeholder="Email" class="form-control" formControlName="email"
                    [ngClass]="{'is-invalid': !ctaForm.controls.email.valid && ctaForm.controls.email.dirty}" />
                </div>
                <button type="submit" class="btn btn-pink min-width">Continue</button>
              </form>
            </div>
            <div class="cta-step text-center text-md-left" [ngClass]="{'none': ctaStepActivated !== 3}"
              *ngIf="interestSelected">
              <!--Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="interestSelected.isRedirection && interestSelected.link">
                <div class="form-group">
                  <a [href]="interestSelected.link" class="btn btn-pink min-width"
                    target="_blank">{{interestSelected.text}}</a>
                </div>
                <div class="text-smaller text-gray">
                  By clicking this link, you will be leaving GoodSeeker.com.
                  <br>
                  Security and privacy policies may be different than ours.</div>
              </div>
              <!--Don't Has interestSelected-->
              <div class="cta-step__content text-center text-md-left" *ngIf="!(interestSelected.isRedirection && interestSelected.link)">
                <h5 class="mb-3">
                  {{interestSelected.thankYouText}}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <ng-container *ngIf="(isNudgeDonation && storyDetail.cta.show_author_information ) || (isNudgeBasic && storyDetail.cta.show_author_information)  ||  isNudgeComplex">
        <div class="cta__button sidebar_button" style="color: white" [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="toggleShowActive()">
          <h6>{{storyDetail.cta.button}}</h6>
          <button class="btn btn-trans ml-3" style="color: #fff">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button>
        </div>
      </ng-container>
  
      <ng-container *ngIf="(isNudgeDonation &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button sidebar_button"  [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color, 'color':button_text_color, 'border-color': button_color}" (click)="openDonation()">
          <h6>{{storyDetail.cta.button}}</h6>
          <!-- <button class="btn btn-trans ml-3">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button> -->
        </div>
      </ng-container>

      <ng-container *ngIf="(isNudgeBasic &&  !storyDetail.cta.show_author_information)">
        <div class="cta__button sidebar_button"  [ngClass]="{'sidebar_mobile_button':isSmallScreen}"  [ngStyle]="{'background-color':button_color,  'color':button_text_color, 'border-color': button_color}" (click)="addCtaHit(storyDetail.cta.link)">
          <h6>{{storyDetail.cta.button}}</h6>
          <!-- <button class="btn btn-trans ml-3">
            <svg role="img">
              <use xlink:href="#icon-expand"></use>
            </svg>
          </button> -->
        </div>
      </ng-container>
  
    </div>
  </div>
</ng-container>