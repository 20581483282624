import { QuestionaireApiModel } from "./questionaire-input.api-model";

export interface StoryCtaInputApiModel {
    text: string,
    button: string,
    config: any
}

export class StoryCtaInputApiModel {
    constructor() {

    }
}

export interface CreateStoryInputApiModel {
    id: number,
    title: string,
    content_raw: string,
    campaign_id_slug: number | string,
    company_id_slug: number | string,
    public: boolean,
    boost_requested: boolean,
    public_requested?:boolean,
    approved: boolean,
    completed: boolean,
    cta: StoryCtaInputApiModel,
    steps: any,
    mark_internal: any;
    created_by: any,
    questionnaire: QuestionaireApiModel
    published_to_draft?: any;
    video_token?:any;
    campaign_id?:any;
    is_footnote_included?: boolean;
    question_id_campaign?:any;
    is_video?: boolean;
    only_question?: any;
    story_prompt?: any;
    token?: any;
    user_requested?:any;
    interview_content?:any;
    interview_style?:boolean;
    story_question_id?:any;
    thumbnail_url?:any;
    default_prompt?:any;
}

export class CreateStoryInputApiModel {
    constructor() {
    }
}
