
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from '@framework/services';

@Injectable()
export class ClientHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {

    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authService.cognito.getIdToken()).pipe(
            switchMap(token => {
                if (request.url === '/api/v3/companies/' && request.method === 'POST' && token) {
                    const headers = request.headers
                        .set('Authorization', 'Bearer ' + token)
                        .append('Content-Type', 'application/json');
                    const requestClone = request.clone({
                        headers
                    });
                    return next.handle(requestClone);
                } else {
                    return next.handle(request);
                }
            })
        );
    }
}
