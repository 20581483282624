import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { storyActions } from '@modules/company-admin/store/actions';
import { CoreState } from '@app/store/core/states';
import { companyActions, companyEmbedActions } from '@modules/company/store/actions';
import { config } from 'process';

@Component({
  selector: 'co-ss-embed-modal-container',
  templateUrl: './ss-embed-modal-container.component.html',
  styleUrls: ['./ss-embed-modal-container.component.scss'],
})

export class SsEmbedModalContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public iFrameForm: UntypedFormGroup;
  public rssForm: UntypedFormGroup;
  public embedButtonForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  private formChangeSubscription: Subscription;
  private loadEmbedSucccess:Subscription;
  public baseUrl: string;
  public queryData: any;
  public embedStoryFeed:any;
  public loadEmbedStoryFeedSucccess: Subscription

  public activeTab: string;
  private rssToken: string;
  public backgroundColor:any;
  public embedresponse:any;
  public textColor:any;
  public border_radius:any = '5px';
  public text_align:any ='center';
  public fontSize:any = '15px'
  public text_decoration:any = 'none';
  public width:any = '140px';
  public height:any = '45px';
  public type:string = 'POST'
  public feedType:string = 'POST';
  public paddingbottom:string = '2px';
  public paddingleft:string = '2px';
  public paddingright:string = '2px';
  public paddingtop:string = '2px';
  private loadColorSuccessSubscription: Subscription;
  public navigation_color:any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store$: Store<CoreState>,
    private actions$: Actions,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.getRSSToken();
    if (!_.isEmpty(this.initialState)) {
      this.baseUrl = this.initialState;
    } else {
      this.baseUrl = window.location.origin;
    }

    this.initForm();
    this.activeTab = 'iframe';
  }

  // Life cycle hook
  protected onInit(): void {
    if (this.initialState && this.initialState.sendData) {
      this.queryData = this.initialState.sendData;
      this.store$.dispatch(new storyActions.GetColor(this.initialState?.sendData?.companySlug));
      if(this.initialState.sendData.from == 'campaign'){
        this.store$.dispatch(new companyEmbedActions.LoadEmbed(false,this.initialState.sendData?.campaign_Id))
        this.store$.dispatch(new companyEmbedActions.LoadEmbedFeed('campaign',this.initialState.sendData?.campaign_Id))
      }
      else if(this.initialState.sendData.from == 'spotlight'){
        this.store$.dispatch(new companyEmbedActions.LoadEmbedFeed('spotlight',this.initialState.sendData?.spotlight_id))
      }
      else{
        this.store$.dispatch(new companyEmbedActions.LoadEmbed(true,this.initialState.sendData?.company_Id))
        this.store$.dispatch(new companyEmbedActions.LoadEmbedFeed('company',this.initialState.sendData?.company_Id))
      }


    }
    this.subscribe();

    // 

    // this.queryData = this.initialState.sendData;

    // this.store$.dispatch(new companyEmbedActions.LoadEmbed(false,this.initialState.sendData?.campaign_Id))





    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
  }
  protected onDestroy(): void {
    const oldstyleElement = document.getElementById('staticStyles');
    if (oldstyleElement) {
      oldstyleElement.parentNode.removeChild(oldstyleElement);
    }
    const doldstyleElement = document.getElementById('dynamicStyles');
    if (doldstyleElement) {
      doldstyleElement.parentNode.removeChild(doldstyleElement);
    }

    this.loadEmbedSucccess.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
    this.embedForm = this.formBuilder.group({
      lineCount: [3],
      foregroundColor: ['#f40b5f'],
      backgroundColor: ['#f40b5f'],
      highlightColor: ['#f40b5f'],
      openToRadio: ['modalWindow', Validators.required],
      selectRadio: ['tile', Validators.required],
      selectCheckbox: ['public', Validators.required],
      isTitle: [true],
      isAuthor: [true],
      isDate: [true],
      isTags: [true],
      isImage: [true],
      isBody: [true],
      isCta:[true],
      from_data:[''],
      custom_css:[''],
      border_radius: ['2px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      border_color: ['#ffff'],
      headline_font_size: ['15px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      body_font_size: ["11px",[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      font_color_hover: ['#ffff'],
      title_color: ['#00000'],
      body_color: ["#ffff"],
      hover_text: ["#ffff"],
      hover_button: ["test button"],
      headerCode: [{ value: '', disabled: true }],
      bodyCode: [{ value: '', disabled: true }],
      styleCode: [{ value: '', disabled: true }]
    });

    this.iFrameForm = this.formBuilder.group({
      height: [100, Validators.required],
      width: [100, Validators.required],
      showHeader:[true],
      showtagheader:[true],
      isSpotlight:[true],
      isCampaigns:[true],
      isStories:[true],
      isFooter: [true],
      widthList:['%'],
      heightList:['%'],
      bodyCode: [{ value: '', disabled: true }],
      selectCheckbox: ['public', Validators.required],
      selectRadio: [false, Validators.required]
    })


    this.rssForm = this.formBuilder.group({
     value:[{ value: '', disabled: true }],
     publicValue:[{ value: '', disabled: true }]
    })

    this.embedButtonForm = this.formBuilder.group({
      backgroundColor: ['#373e85'],
      textColor: ['#fff'],
      buttonText: ["Submit a Story"],
      selectRadio: ['_self', Validators.required],
      headerCode: '',
      bodyCode: '',
      height: [45 ],
      width: [140],
      paddingbottom:['2px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      paddingleft:['2px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      paddingright:['2px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      paddingtop:['2px',[Validators.pattern(/^\d+(px|vh|%|rem)$/),Validators.required]],
      widthList:['px'],
      heightList:['px'],
      bordervalue:['px'],
      text_align:['center'],
      border_radius:[5],
      fontmeasurelist:['px'],
      fontsize:['15'],
      styleCode:[],
      customStyleCode:[]
    });
    this.backgroundColor = '#f40b5f';
    this.textColor = '#fff'

  }

  get updatedIframeCode() {
    if (this.iFrameForm && this.iFrameForm.value && this.initialState.sendData.from == 'company') {
      
      var otherFilter = '';
      if(this.iFrameForm.value.isSpotlight){
        otherFilter+= 'showSpotlight=true'
      }if(this.iFrameForm.value.isCampaigns){        
        // otherFilter+= this.iFrameForm.value.isSpotlight ?  '&showCampaign=true' : 'showCampaign=true'
        otherFilter+=  '&showCampaign=true'
      }
      if(this.iFrameForm.value.isStories){        
        // otherFilter+= this.iFrameForm.value.isCampaigns ?  '&showStories=true' : 'showStories=true'
        otherFilter+=   '&showStories=true'
      }
      if(this.iFrameForm.value.showHeader){        
        // otherFilter+= this.iFrameForm.value.isStories ?  '&showHeader=true' : 'showHeader=true'
        otherFilter+= '&showHeader=true';
      }
      if(this.iFrameForm.value.showtagheader){        
        // otherFilter+= this.iFrameForm.value.showHeader ?  '&showtagheader=true' : 'showtagheader=true'
        otherFilter+=  '&showtagheader=true'
      }
      var iframeContext =   `<iframe src="${window.location.protocol}//${window.location.host}/`
      if(this.iFrameForm.value.isStories){
        // iframeContext  = `<iframe src="${window.location.protocol}//${window.location.host}/`;
       iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug : 'company';
}
      if(!this.iFrameForm.value.isStories && !this.iFrameForm.value.isSpotlight && this.iFrameForm.value.isCampaigns){
        // iframeContext  = `<iframe src="${window.location.protocol}//${window.location.host}/`;
        iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug+'/campaigns' : 'company/campaigns';
      }
      if(!this.iFrameForm.value.isStories && this.iFrameForm.value.isSpotlight && !this.iFrameForm.value.isCampaigns){
        // iframeContext  = `<iframe src="${window.location.protocol}//${window.location.host}/`;
        iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug+'/featured' : 'company/featured';
      }


      if(!this.iFrameForm.value.isStories && this.iFrameForm.value.isSpotlight && this.iFrameForm.value.isCampaigns){
        // iframeContext  = `<iframe src="${window.location.protocol}//${window.location.host}/`;
        iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug+'/campaigns' : 'company/campaigns';
      }

      
      
      iframeContext+= otherFilter ? '?'+otherFilter+'&showfooter='+this.iFrameForm.value.isFooter+'"' : '?'+'showfooter='+this.iFrameForm.value.isFooter+'"'

      iframeContext += ' height="'+this.iFrameForm.value.height+this.getHeight()+'"'+' width="'+this.iFrameForm.value.width+this.getwidth()+'"';;
      iframeContext += this.iFrameForm.value.selectRadio ? '></iframe>' : ' frameborder="0"'+"></iframe>";
      this.iFrameForm.controls.bodyCode.setValue(iframeContext)
    }
    if (this.iFrameForm && this.iFrameForm.value && this.initialState.sendData.from == 'campaign') {
      var iframeContext = `<iframe src="${window.location.protocol}//${window.location.host}/`;
      iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug +'/campaigns/'+this.initialState.sendData.campaigntitle : 'company/campaigns/'+this.initialState.sendData.campaigntitle;
      if(this.iFrameForm.value.showHeader || this.iFrameForm.value.showtagheader || this.iFrameForm.value.isFooter){
        // iframeContext += this.iFrameForm.value.showHeader && this.iFrameForm.value.showtagheader ? '?showtagheader=true&showHeader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' : 
        // this.iFrameForm.value.showHeader && !this.iFrameForm.value.showtagheader ? '?showHeader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' :
        // !this.iFrameForm.value.showHeader && this.iFrameForm.value.showtagheader ? '?showtagheader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' : ''

        iframeContext+= `?showtagheader=${this.iFrameForm.value.showtagheader ? 'true' : 'false'}&showHeader=${this.iFrameForm.value.showHeader ? 'true' : 'false'}&showfooter=${this.iFrameForm.value.isFooter ? 'true"' : 'false"'}`
      }
      else{
        iframeContext += '?showtagheader=false&showHeader=false&showfooter=false"';
      }
      iframeContext += ' height="'+this.iFrameForm.value.height+this.getHeight()+'"'+' width="'+this.iFrameForm.value.width+this.getwidth()+'"';;
      iframeContext += this.iFrameForm.value.selectRadio ? '></iframe>' : ' frameborder="0"'+"></iframe>";
      this.iFrameForm.controls.bodyCode.setValue(iframeContext)
    }

    if (this.iFrameForm && this.iFrameForm.value && this.initialState.sendData.from == 'spotlight') {
      var iframeContext = `<iframe src="${window.location.protocol}//${window.location.host}/`;
      iframeContext += this.iFrameForm.value.selectCheckbox === 'public' ? 'c/' + this.queryData.companySlug +'/featured/'+this.initialState.sendData.spotlighttitle: 'company/featured'+this.initialState.sendData.spotlighttitle;
      if(this.iFrameForm.value.showHeader || this.iFrameForm.value.showtagheader || this.iFrameForm.value.isFooter){
        // iframeContext += this.iFrameForm.value.showHeader && this.iFrameForm.value.showtagheader ? '?showtagheader=true&showHeader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' : 
        // this.iFrameForm.value.showHeader && !this.iFrameForm.value.showtagheader ? '?showHeader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' :
        // !this.iFrameForm.value.showHeader && this.iFrameForm.value.showtagheader ? '?showtagheader=true'+'&showfooter='+this.iFrameForm.value.isFooter+'"' : ''
        iframeContext+= `?showtagheader=${this.iFrameForm.value.showtagheader ? 'true' : 'false'}&showHeader=${this.iFrameForm.value.showHeader ? 'true' : 'false'}&showfooter=${this.iFrameForm.value.isFooter ? 'true"' : 'false"'}`
      }
      else{
        iframeContext += '?showtagheader=false&showHeader=false&showfooter=false"';
      }
      iframeContext += ' height="'+this.iFrameForm.value.height+this.getHeight()+'"'+' width="'+this.iFrameForm.value.width+this.getwidth()+'"';
      iframeContext += this.iFrameForm.value.selectRadio ? '></iframe>' : ' frameborder="0"'+"></iframe>";
      this.iFrameForm.controls.bodyCode.setValue(iframeContext)
    }

    return;
  }

  subscribe(){
    this.loadEmbedSucccess =  this.actions$.pipe(
      ofType<companyEmbedActions.LoadEmbedSuccess>(companyEmbedActions.ActionTypes.LOAD_EMBED_SUCCESS)
    ).subscribe((action: any) => {
      if(action.response && action.response.data){
       this.embedresponse = action.response.data;
       if(this.embedresponse){
        this.type = 'PATCH'
       }
       else{
        this.type = 'POST'
       }
      }
    })

    this.loadEmbedStoryFeedSucccess =  this.actions$.pipe(
      ofType<companyEmbedActions.LoadEmbedFeedSuccess>(companyEmbedActions.ActionTypes.LOAD_EMBED_FEED_SUCCESS)
    ).subscribe((action: any) => {
      if(action.response && action.response.data){
       this.embedStoryFeed = action.response.data;
       if(this.embedStoryFeed){
        this.feedType = 'PATCH'
       }
       else{
        this.feedType = 'POST'
       }
      }
    })

    this.formChangeSubscription = this.embedButtonForm.valueChanges.subscribe((res)=>{
      console.log(res);
      this.height = res.height ? res.height+res.heightList : '45px';
      this.width = res.width ? res.width+res.widthList : '140px';
      this.text_align = res.text_align ? res.text_align : 'center'; ;
      this.border_radius = res.border_radius ? res.border_radius+res.bordervalue : '4px';
      this.fontSize = res.fontsize ? res.fontsize+res.fontmeasurelist : '15px'
      this.backgroundColor = res.backgroundColor;
      this.textColor = res.textColor;
      this.paddingbottom = res.paddingbottom ? res.paddingbottom : '2px';
      this.paddingtop = res.paddingtop ? res.paddingtop : '2px';
      this.paddingleft = res.paddingleft ? res.paddingleft : '2px';
      this.paddingright = res.paddingright ? res.paddingright : '2px';
      var data = `
      #${this.initialState?.sendData?.paraentDiv} { 
       text-align:${this.text_align}
      }
      .${this.initialState?.sendData?.btnclass} {
       padding:0.8rem 0.5rem;
       line-height: 1.5rem;
       height:${this.height};
       width:${this.width};
       border-radius:${this.border_radius};
       color:${this.textColor};
       background-color:${this.backgroundColor};
       font-size:${this.fontSize};
       border:none;
       padding-bottom: ${this.paddingbottom};
       padding-top:  ${this.paddingtop};
       padding-left:  ${this.paddingleft};
       padding-right:  ${this.paddingright};
      }
      
   `
   this.showStaticToButton(data);
      if(res.customStyleCode){
       this.showStylesToButton(res.customStyleCode);
      }
     })

     this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.navigation_color = action.response.find(item => item.button == 6).color;
       }
      

    })
  }
  getHeight(){
    if(+this.iFrameForm.value.height > 100 && this.iFrameForm.value.heightList == '%'){
      this.iFrameForm.controls.height.setValue(100);
       return this.iFrameForm.value.heightList
    }
    else{
      return this.iFrameForm.value.heightList
    }
  }
  getwidth(){
    if(+this.iFrameForm.value.width > 100 && this.iFrameForm.value.widthList == '%'){
      this.iFrameForm.controls.width.setValue(100);
       return this.iFrameForm.value.widthList
    }
    else{
      return this.iFrameForm.value.widthList
    }
  }

  public close() {
    this.onClose.emit();
  }

  public getScript() {
    if (!this.queryData) {
      console.error('Invalid querydata');
    }
    let widgetURL = this.baseUrl + '/assets/front-end-minify/js/widget.js';
    let formData = this.embedForm.getRawValue();

    var config:any = {
      "title": formData.isTitle,
      "author": formData.isAuthor,
      "date": formData.isDate,
      "tags": formData.isTags,
      "cta": formData.isCta,
      "display_format": formData.selectRadio,
      "open_format":formData.openToRadio == 'modalWindow' ? 'modal' : 'window',
      "from_data": this.initialState.sendData.from,
      "custom_css": formData.custom_css,
      "border_radius": formData.border_radius,
      "border_color": formData.border_color,
      "headline_font_size": formData.headline_font_size,
      "body_font_size": formData.body_font_size,
      "font_color_hover":formData.font_color_hover,
      "title_color": formData.title_color,
      "body_color": formData.body_color,
      "hover_text": formData.hover_text,
      "hover_button": formData.hover_button,
      "background_color": formData.backgroundColor,
      "foreground_color": formData.foregroundColor,
      "highlight_color": formData.highlightColor,
    };

    if(this.initialState.sendData.from == 'spotlight' || this.initialState.sendData.from == 'campaign'){

      config.query_data = this.initialState.sendData.queryWithoutPaination
    }
    else{
      config.query_data = ''
    }
    if(config.from == 'spotlight'){
      var data = this.initialState.sendData?.deletedId;
      if(data.length > 0 ){
        config.deleted_id = data.join(',')
      }
      else{
        config.deleted_id = 'nodata'
      }
    }
    else{
      config.deleted_id = 'nodata'
    }

    var configForModal:any = {
      from:this.initialState.sendData?.from,
      companyId: this.queryData.companyId,
      Id:this.initialState.sendData.javascriptId,
      apiURL: this.baseUrl + '/',
    }

    if(this.initialState.sendData.from == 'spotlight'){
       config.id_spotlight = this.initialState.sendData.spotlight_id
       configForModal.id = this.initialState.sendData.spotlight_id
    }
    else if(this.initialState.sendData.from == 'campaign'){
      config.id_campaign = this.initialState.sendData.campaign_Id,
      configForModal.id = this.initialState.sendData.campaign_Id
    }
    else{
      config.id_company = this.initialState.sendData.company_Id,
      configForModal.id = this.initialState.sendData.company_Id
    }


    const highlightedText = JSON.stringify(configForModal, undefined, 2).replace(/\s/g, '');

    this.store$.dispatch(new companyEmbedActions.PostPatchEmbedFeed(config,this.feedType))

    this.embedForm.controls.headerCode.setValue('<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script><script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script><script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.min.js" integrity="sha384-VHvPCCyXqtD5DqJeNxl2dtTyhF78xXNXdkwX1CZeRusQfRKp+tA7hAShOK/B/fQ2" crossorigin="anonymous"></script><script type="text/javascript" src="' + this.baseUrl + '/assets/front-end-minify/js/vendor.min.js"></script><script type="text/javascript" src="' + widgetURL + '"></script>' + '<script type="text/javascript">' + 'window.goodSeekers.init(' + highlightedText + ')</script>');

    this.embedForm.controls.bodyCode.setValue(`<div id="${this.initialState.sendData.javascriptId}"></div>`);

    this.embedForm.controls.styleCode.setValue(`    /* Main area for all stories */
    #gs-story-embed { }
    
    /* The main story tile */
    #gs-story-embed .gs-story-item { }
    
    /* Tile container for all elements in story tile */
    #gs-story-embed .gs-story-item__wrap { }
    
    /* Image area on story tile */
    #gs-story-embed .gs-story-item__thumb { }
    
    /* The main story tile Text container */
    #gs-story-embed .gs-story-item__contents { }
    
    /* Date shown on story */
    #gs-story-embed .gs-story-item__tile-date { }
    
    /* Title shown on story */
    #gs-story-embed .gs-story-item__title { }
    
    /* Short story description shown on hover */
    #gs-story-embed .gs-story-item__body { }
    
    /* Video section when present */
    #gs-story-embed .gs-story-item__video{ }
    
    /* Container for all author details */
    #gs-story-embed .gs-story-item__author { }
    
    /* Author avatar */
    #gs-story-embed .gs-story-item__author .avatar { }
    
    /* Author name */
    #gs-story-embed .gs-story-item__author span { }
    
    /* Container for story hashtags */
    #gs-story-embed .gs-story-item__tags { }
    
    /* Hashtag style on story tile */
    #gs-story-embed .gs-story-item__tags .tag { }
    
    /* Container for content displayed on overlay */ 
    #gs-story-embed .gs-story-item__detail-more { }
    
    /* Color of hover on story */
    #gs-story-embed .gs-story-item:hover .gs-story-item__detail-more { }
    
    /* Read more button on story tile */
    #gs-story-embed .gs-story-item__detail-more a { }
`);
    this.IsScriptCreated = true;
    this.showPreview = false;
  }

  public loadScript() {

    let formData = this.embedForm.getRawValue();
    var config = {
      visibility: {
        title: formData.isTitle,
        author: formData.isAuthor,
        date: formData.isDate,
        tags: formData.isTags,
        image: formData.isImage,
        body: formData.isBody
      },
      layout: {
        fgColor: formData.foregroundColor,
        bgColor: formData.backgroundColor,
        hlColor: formData.highlightColor
      },
      view: {
        open: formData.openToRadio === 'modalWindow' ? 'modal' : 'window',
        type: formData.selectRadio === 'list' ? 'list' : 'tile',
      },
      contentLines: formData.lineCount,
      apiURL: this.baseUrl + '/',
      type: this.queryData.type,
      companyId: this.queryData.companyId,
      queryData: this.queryData.queryData.replace("limit=12", "limit=4")
    };
    console.log('preparing to load...')
    let node1 = document.createElement('script');
    node1.src = "https://code.jquery.com/jquery-3.4.1.slim.min.js";
    node1.type = 'text/javascript';
    node1.async = true;

    let node2 = document.createElement('script');
    node2.src = "assets/front-end-minify/js/vendor.min.js";
    node2.type = 'text/javascript';
    node2.async = true;

    let node3 = document.createElement('script');
    node3.src = "assets/front-end-minify/js/widget.js";
    node3.type = 'text/javascript';
    node3.async = true;
    setTimeout(() => {
      this.showPreview = true;
      document.getElementsByTagName('head')[0].appendChild(node1);
      document.getElementsByTagName('head')[0].appendChild(node2);
      document.getElementsByTagName('head')[0].appendChild(node3);

    }, 1000);
    setTimeout(() => {
      window["goodSeekers"].init(config)
    }, 2000);
  }

  isMyScriptLoaded(url) {
    if (!url) { return };
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
      if (scripts[i].src == url) return true;
    }
    return false;
  }

  activateTab(tab: string) {
    this.activeTab = tab;

    if(this.activeTab == 'RSS'){
      if (!this.rssToken) {
        this.getRSSToken();
        return;
      }
      const url = `${window.location.origin}/api/v3/companies/${this.queryData.companyId}/stories/?${this.queryData.queryWithoutPaination}&rss=1&token=${this.rssToken}${this.initialState.sendData.from == 'spotlight' ? '&spotlight_only=True':''}`;
      let encodedInternalUrl = encodeURI(url);
     this.rssForm.controls.value.setValue(encodedInternalUrl)
     const publicUrl  = `${window.location.origin}/api/v3/companies/${this.queryData.companyId}/stories/?${this.queryData.queryWithoutPaination}&public=1&rss=1${this.initialState.sendData.from == 'spotlight' ? '&spotlight_only=True':''}`
     let encodedPublicUrl = encodeURI(publicUrl);
     this.rssForm.controls.publicValue.setValue(encodedPublicUrl)
    }
    if(this.activeTab == 'embedButton'){
      this.initialState.sendData.btnclass   = this.initialState?.sendData?.btnclass.replace(/[^\w\s]/g, '');
      this.initialState.sendData.paraentDiv = this.initialState?.sendData?.paraentDiv.replace(/[^\w\s]/g, '');
      if (this.initialState && this.initialState.sendData) {
  
        this.queryData = this.initialState.sendData;
      }
      setTimeout(()=>{
        const element = document.getElementById('embed_btn');
        element.classList.add(this.initialState?.sendData?.btnclass);
      },1000)

      if(this.embedresponse){
        this.height = this.embedresponse?.height + this.embedresponse?.heightmeasurement;
        this.width = this.embedresponse?.width + this.embedresponse?.widthmeasurement;
        this.border_radius = this.embedresponse?.border_radius + this.embedresponse?.border_radius_measurement
        this.textColor = this.embedresponse?.text_clr
        this.backgroundColor = this.embedresponse?.background_clr
        this.fontSize = this.embedresponse?.font_size + this.embedresponse?.fontmeasurement

        this.embedButtonForm.patchValue({
          backgroundColor: this.embedresponse?.background_clr,
          textColor: this.embedresponse?.text_clr,
          buttonText: this.embedresponse?.button_text.replace(/&nbsp;/g, " "),
          selectRadio: this.embedresponse?.open,
          height: this.embedresponse?.height,
          width: this.embedresponse?.width,
          widthList:this.embedresponse?.widthmeasurement,
          heightList:this.embedresponse?.heightmeasurement,
          bordervalue:this.embedresponse?.border_radius_measurement,
          text_align:this.embedresponse?.text_align,
          border_radius:this.embedresponse?.border_radius,
          fontmeasurelist:this.embedresponse?.fontmeasurement,
          fontsize:this.embedresponse?.font_size,
          paddingbottom :this.embedresponse?.paddingbottom,
          paddingtop : this.embedresponse?.paddingtop,
          paddingleft : this.embedresponse?.paddingleft,
          paddingright : this.embedresponse?.paddingright,
          customStyleCode:JSON.parse(this.embedresponse?.embedding_value) != 'nodata' ?  JSON.parse(this.embedresponse?.embedding_value) : ''
        },{emitEvent: false})
      }
  
     
        if(this.initialState?.sendData?.paraentDiv && this.initialState?.sendData?.btnclass){
     this.embedButtonForm.patchValue({
      styleCode: `
     #${this.initialState?.sendData?.paraentDiv} { 
      text-align:${this.text_align}
     }
     .${this.initialState?.sendData?.btnclass} {
      padding:0.8rem 0.5rem;
      line-height: 1.5rem;
      height:${this.height};
      width:${this.width};
      border-radius:${this.border_radius};
      color:${this.textColor};
      background-color:${this.backgroundColor};
      font-size:${this.fontSize};
      border:none;
      padding-bottom: ${this.paddingbottom};
      padding-top:  ${this.paddingtop};
      padding-left:  ${this.paddingleft};
      padding-right:  ${this.paddingright};
     }
     
  `},{emitEvent: false})
  this.showStaticToButton(this.embedButtonForm.controls.styleCode.value);
         if(this.embedresponse && this.embedresponse?.embedding_value && JSON.parse(this.embedresponse?.embedding_value) != 'nodata'){
          this.embedButtonForm.patchValue({
            customStyleCode: JSON.parse(this.embedresponse?.embedding_value)
          },{emitEvent:false})
          this.showStylesToButton(this.embedButtonForm.controls.customStyleCode.value)
         }
        }
    }

    if(this.activeTab == 'embedCode'){

      if(this.embedStoryFeed){

        this.embedForm.patchValue({
          foregroundColor: this.embedStoryFeed?.foreground_color,
          backgroundColor: this.embedStoryFeed?.background_color ,
          highlightColor:this.embedStoryFeed?.highlight_color ,
          openToRadio: this.embedStoryFeed?.open_format == 'modal' ? 'modalWindow' : 'newWindow' ,
          selectRadio:this.embedStoryFeed?.display_format ,
          isTitle: this.embedStoryFeed?.title,
          isAuthor: this.embedStoryFeed?.author,
          isDate:  this.embedStoryFeed?.date,
          isTags: this.embedStoryFeed?.tags,
          isImage: true,
          isBody: true,
          isCta:this.embedStoryFeed?.cta,
          custom_css:this.embedStoryFeed?.custom_css,
          border_radius:this.embedStoryFeed?.border_radius,
          border_color: this.embedStoryFeed?.border_color,
          headline_font_size: this.embedStoryFeed?.headline_font_size,
          body_font_size: this.embedStoryFeed?.body_font_size,
          hover_text:this.embedStoryFeed?.hover_text,
          font_color_hover: this.embedStoryFeed?.font_color_hover,
          title_color: this.embedStoryFeed?.title_color,
          body_color: this.embedStoryFeed?.body_color,
          hover_button: this.embedStoryFeed?.hover_button,
        },{emitEvent: false})
      }
  

    }
  }

  private getRSSToken() {
    this.rssFeedHttpService.getRSSToken().subscribe(data => {
      if (data.token) {
        this.rssToken = data.token;
      }
    });
  }

  public getEmbedButtonScript() {
    if(!this.queryData) {
      console.error('Invalid querydata');
    }
    let testimonilaURL = this.baseUrl + '/assets/front-end-minify/js/testimonial.js';
    // let formData = this.embedForm.getRawValue();
    this.embedButtonForm.controls.styleCode.setValue(`${this.embedButtonForm.controls.customStyleCode.value ? this.embedButtonForm.controls.customStyleCode.value : 'nodata'} `)
 var cssString = this.embedButtonForm.controls.styleCode.value;
 console.log("css",cssString)
    var config = {
      apiURL: this.initialState?.sendData?.url,
      companySlug: this.queryData.companySlug,
      paraentDiv:this.initialState?.sendData?.paraentDiv,
      btnClass:this.initialState?.sendData?.btnclass,
      from:this.initialState.sendData?.from,
      apiEnd:this.initialState.sendData?.response,
      Id: this.initialState.sendData?.from == 'campaign' ? this.initialState.sendData?.from?.campaign_Id : this.initialState.sendData?.from?.company_Id
    };

var obj:any ={ 
company_id:this.initialState.sendData.company_Id,
embedding:cssString,
button_text: this.embedButtonForm.controls.buttonText.value.replace(/\s/g, '&nbsp;'),
background_clr:this.backgroundColor,
text_clr:this.textColor,
font_size: this.embedButtonForm.controls.fontsize.value ? this.embedButtonForm.controls.fontsize.value : '15',
fontmeasurement:this.embedButtonForm.controls.fontmeasurelist.value ? this.embedButtonForm.controls.fontmeasurelist.value : 'px',
width:this.embedButtonForm.controls.width.value ? this.embedButtonForm.controls.width.value : '140',
widthmeasurement:this.embedButtonForm.controls.widthList.value ? this.embedButtonForm.controls.widthList.value : 'px',
height:this.embedButtonForm.controls.height.value ? this.embedButtonForm.controls.height.value : '45',
heightmeasurement:this.embedButtonForm.controls.heightList.value ? this.embedButtonForm.controls.heightList.value : 'px',
text_align:this.embedButtonForm.controls.text_align.value ? this.embedButtonForm.controls.text_align.value : 'center',
border_radius:this.embedButtonForm.controls.border_radius.value ? this.embedButtonForm.controls.border_radius.value : '4',
border_radius_measurement:this.embedButtonForm.controls.bordervalue.value ? this.embedButtonForm.controls.bordervalue.value : 'px',
open:this.embedButtonForm.controls.selectRadio.value ? this.embedButtonForm.controls.selectRadio.value : '',
paddingbottom: this.embedButtonForm.controls.paddingbottom.value ? this.embedButtonForm.controls.paddingbottom.value : '2px',
paddingtop: this.embedButtonForm.controls.paddingtop.value ? this.embedButtonForm.controls.paddingtop.value : '2px',
paddingleft: this.embedButtonForm.controls.paddingleft.value ? this.embedButtonForm.controls.paddingleft.value : '2px',
paddingright: this.embedButtonForm.controls.paddingright.value ? this.embedButtonForm.controls.paddingright.value : '2px',
}

if(this.initialState.sendData?.from == 'campaign'){
      obj.campaign_id = this.initialState.sendData.campaign_Id
    }

this.store$.dispatch(new companyEmbedActions.PostPatchEmbed(obj,this.type))

    const highlightedText = JSON.stringify(config, undefined, 2).replace(/\s/g, '');
    this.embedButtonForm.controls.headerCode.setValue('<script type="text/javascript" src="https://code.jquery.com/jquery-3.4.1.slim.min.js"></script><script type="text/javascript" src="' + this.baseUrl + '/assets/front-end-minify/js/vendor.min.js"></script><script type="text/javascript" src="' + testimonilaURL + '"></script>' + '<script type="text/javascript">' + 'window.goodSeekers.init(' + highlightedText + ')</script>');
    this.embedButtonForm.controls.bodyCode.setValue(`<div id=${this.initialState?.sendData?.paraentDiv}></div>`);
    this.IsScriptCreated = true;
  }

  onBackgroundcolorChange(data){
    this.backgroundColor = data;
    this.embedButtonForm.controls.backgroundColor.setValue(data);
  }

  onTextcolorChange(data){
    this.textColor = data;
    this.embedButtonForm.controls.textColor.setValue(data);
  }

  showStylesToButton(data){
    const oldstyleElement = document.getElementById('dynamicStyles');
    if (oldstyleElement) {
      oldstyleElement.parentNode.removeChild(oldstyleElement);
    }
    const styleElement = document.createElement('style');
          styleElement.id = 'dynamicStyles';

            if (styleElement) {
                styleElement.textContent += data;
                document.head.appendChild(styleElement);
            }
  }

  showStaticToButton(data){
    const oldstyleElement = document.getElementById('staticStyles');
    if (oldstyleElement) {
      oldstyleElement.parentNode.removeChild(oldstyleElement);
    }
    const styleElement = document.createElement('style');
          styleElement.id = 'staticStyles';

            if (styleElement) {
                styleElement.textContent += data;
                document.head.appendChild(styleElement);
            }

  }


}
