import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetUserInputApiModel, SendEmailInputApiModel } from '../models/api-models/input';
import { UserOutputApiModel } from '../models/api-models/output';
import { CreateUserInputApiModel, CreateUserCSVInputApiModel } from '@modules/company-admin/store/models/api-models/input';

@Injectable()
export class UserHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetUserInputApiModel) {
        let requestQuery = queryData.query;
        if (queryData.showRequests) {
            requestQuery = requestQuery.replace('a=0', 'g=1');
        }
        return this.get(`/${companyId}/setting/users/?${requestQuery}`).pipe(
            map((response: ResponsePaginationApiModel<UserOutputApiModel>) => {
                return response;
            })
        );
    }

    public create(companyId: number, payload: CreateUserInputApiModel) {
        return this.post(`/${companyId}/setting/users/add/`, payload).pipe(

            map((response: any) => {
                return response;
            })
        );
    }
    public createCSV(companyId: number, payload: CreateUserCSVInputApiModel) {
        return this.post(`/${companyId}/setting/user/upload`, payload).pipe(

            map((response: any) => {
                return response;
            })
        );
    }

    public sendEmail(companyId: number, data: SendEmailInputApiModel) {
        return this.post(`/${companyId}/setting/users/send-mail/`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public enableAccess(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/users/access/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public disableAccess(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/users/access/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }



    public makeAdmin(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/users/admin/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    public changeUserRoles(companyId: number, ids: number[],roleId) {
        return this.post(`/${companyId}/setting/users/admin-roles/?ids=${ids.join(',')}&rid=${roleId}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeRegular(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/users/admin/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
